import { useState } from 'react'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { ModalContent } from 'components'
import { AttributeModel } from '../../entities'
import { deleteAttributeByID } from 'services/api'

import { ModalBodyDelete } from 'components/Modal/styles'
import { ErrorMessage } from 'components/CardAdmin/styles'

type Props = {
  toggleModal: () => void
  updateData: () => void
  attributeSelected: AttributeModel
}

const DeleteAttributedModal = ({ toggleModal, attributeSelected, updateData }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isConfirm, setIsConfirm] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleDelete = async (): Promise<void> => {
    if (!isConfirm) {
      setErrorMessage('* Campo obrigatório')
      return
    }
    try {
      setIsLoading(true)
      const response = await deleteAttributeByID(attributeSelected.id)
      if (!response) {
        throw new Error()
      }
      updateData()
    } catch (error: any) {
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao remover a pergunta!')
    } finally {
      setIsLoading(false)
      toggleModal()
    }
  }

  return (
    <ModalContent
      title="Remover card"
      handleCloseModal={toggleModal}
      handleSubmit={handleDelete}
      isLoading={isLoading}
      btnText="Confirmar"
      btnColor="#cf1f3f"
    >
      <ModalBodyDelete>
        <div className="question">
          <span className="icon">
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </span>
          <p>Deseja remover esta pergunta?</p>
        </div>
        <p className="admName">{attributeSelected?.question}</p>
        {attributeSelected?.forms && attributeSelected?.forms.length > 0 && (
          <span className="warning-msg">
            <p>Ao remover esta pergunta, será removida dos seguintes formulários:</p>
            {attributeSelected?.forms.map((form) => (
              <div key={form.id} className="formName-list">
                - {form.title}
              </div>
            ))}
          </span>
        )}
        <div className="confirm-action">
          <span className="confirm-msg">
            <input
              type="checkbox"
              onChange={() => {
                setIsConfirm(!isConfirm)
                setErrorMessage('')
              }}
            />
            <p>Confirmo que li e estou ciente da remoção da pergunta.</p>
          </span>
          {errorMessage && <ErrorMessage $fontSize={'0.75rem'}>{errorMessage}</ErrorMessage>}
        </div>
      </ModalBodyDelete>
    </ModalContent>
  )
}

export default DeleteAttributedModal
