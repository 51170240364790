import { SurveyQuestionOptionsProps } from 'components/Fields/SurveyFields/SurveySelect'

export const formatOptions = (list: SurveyQuestionOptionsProps[]): string[] => {
  const newList: string[] = []

  list.sort((a, b) => a.weight - b.weight).forEach(item => {
    newList.push(item.value)
  })

  return newList
}
