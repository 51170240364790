import { useFormContext } from 'react-hook-form'
import { AttributeModel } from '../../entities'

import { ErrorMessage, FormInputText } from './styles'

type Props = {
  attribute: AttributeModel
}

const TextField = ({ attribute }: Props) => {
  const {
    register,
    formState: { errors }
  } = useFormContext()

  const validations = {
    required: {
      value: attribute.required ?? false,
      message: '* Campo obrigatório'
    },
    maxLength: {
      value: attribute.maxLength ?? 25000,
      message: `* Máximo ${attribute.maxLength ?? 25000} caracteres permitidos`
    }
  }

  return (
    <FormInputText>
      <label htmlFor={attribute.id.toString()}>{attribute.question}</label>
      <input
        type="text"
        placeholder={attribute.placeholder ?? ''}
        {...register(attribute.id.toString(), validations)}
      />
      {errors[attribute.id.toString()] && (
        <ErrorMessage>{errors[attribute.id]?.message?.toString()}</ErrorMessage>
      )}
    </FormInputText>
  )
}

export default TextField
