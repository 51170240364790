import { useEffect } from 'react'
import { useGetNews, useWindowDimensions } from 'hooks'
import { ContainerNewsCarousel, ContainerTitle } from './styles'
import NewsListContent from 'components/News/NewsList'

const NewsCarousel = () => {
  const { width } = useWindowDimensions()
  const { newsActive, isLoading, getNews } = useGetNews()

  useEffect(() => {
    getNews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ContainerNewsCarousel $widthScreen={width <= 768 ? `${width}px` : '0px'}>
      <ContainerTitle>
        {'DESTAQUES'}
        <span />
      </ContainerTitle>
      <NewsListContent isLoading={isLoading} newsList={newsActive} />
    </ContainerNewsCarousel>
  )
}

export default NewsCarousel
