/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import {
  ArticleModal,
  Breadcrumb,
  ContainerLoading,
  FormGeneric,
  Layout,
  Notification
} from '../../components'
import { useGetForm } from '../../hooks'
import { ContainerTicketForm } from './styles'

export const TicketForm = () => {
  const { state } = useLocation()
  const { formId } = useParams()
  const { isLoadingForm, form, getForm } = useGetForm()

  const refModal = useRef<HTMLDialogElement>(null)
  const [isModalOpen, setIsOpenModal] = useState(true)
  const [title, setTitle] = useState('')
  const showForm = !isLoadingForm && !isModalOpen
  const showModal = !isLoadingForm && isModalOpen && (form?.articles && form.articles.length > 0)

  const toggleModal = () => {
    if (refModal.current) {
      if (isModalOpen) {
        setIsOpenModal(false)
        setTitle(form?.parentName as string)
        refModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenModal(true)
      refModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const renderComponent = () => {
    if (form?.isEnable) {
      return <FormGeneric form={form} />
    }
    // -- Form in construction---   //
    return <Notification message="No momento não existe nenhum formulário associado a este card." />
  }

  useEffect(() => {
    if (form?.articles.length === 0 && !isLoadingForm) {
      setIsOpenModal(false)
      setTitle(form?.parentName)
      return
    }
    if (showModal && refModal.current) {
      setTitle('Artigos úteis')
      refModal?.current.showModal()
    }
  }, [isLoadingForm, form])

  useEffect(() => {
    if (formId) {
      getForm(formId)
    }
  }, [formId])

  return (
    <Layout title={title}>
      <ContainerTicketForm>
        <Breadcrumb
          titlePage="Novo chamado"
          goToHome={!form?.title || state?.goToHome}
          goBackStep={state?.twoStep}
        />
        <div className="ticket-form-content">
          {isLoadingForm && <ContainerLoading />}
          {showForm && renderComponent()}
          {showModal && form && (
            <ArticleModal
              refModal={refModal}
              isModalOpen={isModalOpen}
              toggleModal={toggleModal}
              articles={form.articles}
              title={form.parentCard}
              formId={form.id}
            />
          )}
        </div>
      </ContainerTicketForm>
    </Layout>
  )
}
