import { InputType } from '../../../../entities'
import SelectAdmField from '../Select'

type ContentProps = {
  type: InputType
  optionsSelectElementFieldName: any
  firstLevel?: boolean
}

export function renderContent ({ type, optionsSelectElementFieldName, firstLevel }: ContentProps) {
  switch (type) {
    case 'select':
      return (
        <SelectAdmField
          optionsSelectElementFieldName={optionsSelectElementFieldName}
          firstLevel={firstLevel}
        />
      )
    case 'checkbox':
      return (
        <SelectAdmField
          optionsSelectElementFieldName={optionsSelectElementFieldName}
          firstLevel={firstLevel}
          hideCategory={true}
        />
      )
    case 'radio':
      return (
        <SelectAdmField
          optionsSelectElementFieldName={optionsSelectElementFieldName}
          firstLevel={firstLevel}
        />
      )
    default:
      return null
  }
}
