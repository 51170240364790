import { OptionSelect } from 'components/Select/Select'
import { AttributeOptionModel } from '../../../entities'

export const setOptions = (list: AttributeOptionModel[]): OptionSelect[] => {
  const _optionValues: OptionSelect[] = []
  list.forEach((opt) => {
    _optionValues.push({ label: opt.value, value: opt.value })
  })
  return _optionValues
}
