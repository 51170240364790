export const options = [
  { label: 'Encerrado', value: 'Encerrado' },
  { label: 'Em atendimento', value: 'Em atendimento' },
  { label: 'Agendado', value: 'Agendado' },
  { label: 'Resolvido', value: 'Resolvido' },
  { label: 'Pendente Usuário', value: 'Pendente Usuário' },
  { label: 'Pendente Fornecedor', value: 'Pendente Fornecedor' },
  { label: 'Pendente Transporte', value: 'Pendente Transporte' },
  { label: 'Pendente Aprovação', value: 'Pendente Aprovação' },
  { label: 'Direcionado', value: 'Direcionado' },
  { label: 'Cancelado', value: 'Cancelado' },
  { label: 'Homologação', value: 'Homologação' },
  { label: 'Finalizado por pendência', value: 'Finalizado por pendência' },
  { label: 'Todos', value: '' }
]

export const styles = {
  control: (provided: any, state: any) => ({
    ...provided,
    boxSizing: 'border-box',
    height: 'fit-content',
    minHeight: '2.3rem',
    width: '12rem',
    minWidth: 'fit-content',
    padding: '0 0.5rem',
    border: state.isFocused ? '1px solid #707070' : '1px solid #d2d2d2',
    boxShadow: state.isFocused ? 0 : 0,
    borderRadius: '0.5rem',
    backgroundColor: state.isFocused ? '#f1f1f1' : '#fff',
    color: '#707070',
    fontSize: '0.875rem',
    borderColor: state.isSelected && 'red'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    margin: '0px',
    padding: '0px',
    minHeight: '2.3rem'
  }),
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
    padding: 0
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    padding: '0',
    minHeight: '2.3rem'
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),

  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: '0'
  }),

  menu: (provided: any) => ({
    ...provided,
    color: '#707070',
    width: '12rem',
    minWidth: 'fit-content',
    zIndex: 100
  })
}
