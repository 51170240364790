import { InputType } from '../../../../entities'
import SurveySelectField from '../SurveySelect'

type ContentProps = {
  type: InputType
  optionsSelectElementFieldName: any
}

export function renderSurveyContent ({ type, optionsSelectElementFieldName }: ContentProps) {
  switch (type) {
    case 'select':
      return (
        <SurveySelectField
          optionsSelectElementFieldName={optionsSelectElementFieldName}
        />
      )
    case 'checkbox':
      return (
        <SurveySelectField
          optionsSelectElementFieldName={optionsSelectElementFieldName}
        />
      )
    case 'radio':
      return (
        <SurveySelectField
          optionsSelectElementFieldName={optionsSelectElementFieldName}
        />
      )
    default:
      return null
  }
}
