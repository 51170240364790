import { ReactNode } from 'react'
import Button from 'components/Button/Button'
import { ModalBody, ModalFooter, ModalHeader } from './styles'

type Props = {
  title: string
  handleCloseModal: () => void
  handleSubmit?: null | (() => void)
  isLoading: boolean
  children: ReactNode
  btnText?: string
  btnColor?: string
  handleOpenModal?: () => void
  btn2Text?: string
  btn2Color?: string
}

const ModalContent = ({
  title,
  handleCloseModal,
  isLoading,
  children,
  handleSubmit,
  btnText = 'Salvar',
  btnColor = '#008000',
  btn2Text = '',
  handleOpenModal,
  btn2Color = '#0372ad'
}: Props) => {
  return (
    <>
      <ModalHeader>
        <span>{title}</span>
        <button onClick={handleCloseModal}>X</button>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        {handleSubmit && (
          <Button
            type="submit"
            isLoading={isLoading}
            text={btnText}
            color={btnColor}
            onClick={handleSubmit}
          />
        )}
        {handleOpenModal && (
          <Button
            type="button"
            isLoading={false}
            text={btn2Text}
            color={btn2Color}
            onClick={handleOpenModal}
          />
        )}
      </ModalFooter>
    </>
  )
}

export default ModalContent
