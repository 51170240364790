import { useContext } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AuthContext } from '../../contexts/auth'
import { useAllTalk, useScript, useShowIframeButton } from '../../hooks/useScript'
import { Button } from 'components'

import { Container, WrapperLogin, ErrorMessage } from './styles'
import logoTJ from '../../assets/logotransparent.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons'

interface IFormInput {
  username: string
  password: string
}

export function SignIn () {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInput>()
  const { login, isLoading, errorMessage } = useContext(AuthContext)
  useScript()
  useAllTalk()
  useShowIframeButton()

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    login(data.username, data.password)
  }

  return (
    <Container>
      <WrapperLogin>
        <img src={logoTJ} alt="logoTJ" className="login-logo" />
        <form onSubmit={handleSubmit(onSubmit)} className="login-form">
          <div className="form-field">
            <FontAwesomeIcon icon={faUser} />
            <input
              type="text"
              placeholder="Digite seu usuário"
              {...register('username', { required: true })}
            />
            {errors.username?.type === 'required' && (
              <ErrorMessage>* Campo obrigatório</ErrorMessage>
            )}
          </div>
          <div className="form-field">
            <FontAwesomeIcon icon={faLock} />
            <input
              type="password"
              placeholder="Digite sua senha"
              {...register('password', { required: true })}
            />
            {errors.password?.type === 'required' && (
              <ErrorMessage>* Campo obrigatório</ErrorMessage>
            )}
          </div>
          <div className="login-error">{errorMessage ? <span>{errorMessage}</span> : null}</div>
          <Button
            type="submit"
            isLoading={isLoading}
            text="Entrar"
            style={{ width: '100%', height: '2.2rem', fontSize: '0.75rem' }}
          />
        </form>

        <a className="attorneyOrDelegate" href="http://servicedeskexterno.tjba.jus.br">
          ACESSO ADVOGADO/DELEGATÁRIO
        </a>

        <div className="footer">
          <span>Service Desk TJBA</span>
          <span>0800 071 8522 / (71)3324-7400</span>
        </div>
      </WrapperLogin>
    </Container>
  )
}
