import { useEffect, useRef, useState } from 'react'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AddButton,
  AddNewsModal,
  AdmLayout,
  DeleteNewsModal,
  EditNewsModal,
  EnableNewsModal,
  Spinner
} from 'components'
import { useGetNews } from 'hooks'
import { INews } from '../../../entities'

import {
  EmptyListMessage,
  ModalWrapper,
  TitlePage,
  WrapperContent,
  WrapperNewsList
} from '../styles'

export const NewsAdmin = () => {
  const { news, isLoading, setOrderNewsList, getNews } = useGetNews()

  const refDragItem = useRef<any>(null)
  const refDragOverItem = useRef<any>(null)
  const refAddModal = useRef<HTMLDialogElement>(null)
  const refEditModal = useRef<HTMLDialogElement>(null)
  const refDeleteModal = useRef<HTMLDialogElement>(null)
  const refEnableModal = useRef<HTMLDialogElement>(null)

  const [newsSelected, setNewsSelected] = useState<INews | null>(null)
  const [isOpenAddModal, setIsOpenAddModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenEnableModal, setIsOpenEnableModal] = useState(false)

  const toggleAddModal = () => {
    if (refAddModal.current) {
      if (isOpenAddModal) {
        setIsOpenAddModal(false)
        refAddModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenAddModal(true)
      refAddModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const toggleEditModal = () => {
    if (refEditModal.current) {
      if (isOpenEditModal) {
        setIsOpenEditModal(false)
        refEditModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenEditModal(true)
      refEditModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const toggleDeleteModal = () => {
    if (refDeleteModal.current) {
      if (isOpenDeleteModal) {
        setIsOpenDeleteModal(false)
        refDeleteModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenDeleteModal(true)
      refDeleteModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const toggleEnableModal = () => {
    if (refEnableModal.current) {
      if (isOpenEnableModal) {
        setIsOpenEnableModal(false)
        refEnableModal?.current.close()
        return
      }
      setIsOpenEnableModal(true)
      refEnableModal?.current.showModal()
    }
  }

  const handleSort = () => {
    if (refDragItem.current === refDragOverItem.current) {
      return
    }

    const _news = [...news]
    const draggedItemContent = _news.splice(refDragItem?.current as unknown as number, 1)[0]
    _news.splice(refDragOverItem.current as unknown as number, 0, draggedItemContent)

    refDragItem.current = null
    refDragOverItem.current = null

    setOrderNewsList(_news)
  }

  useEffect(() => {
    if (!isOpenAddModal && !isOpenDeleteModal && !isOpenEditModal) {
      getNews()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdmLayout title="Administração de destaques">
      <WrapperContent>
        <TitlePage>Destaques</TitlePage>
        <p>
          Adicione os destaques que irão aparecer na tela inicial. Você pode adicionar até 10
          destaques.
        </p>
        <AddButton text="Adicionar destaques" onClick={toggleAddModal} />
        {isLoading && <Spinner />}
        {!isLoading && news.length === 0 && (
          <EmptyListMessage>
            <p>Nenhum destaque encontrado!</p>
          </EmptyListMessage>
        )}
        {!isLoading && news.length > 0 && (
          <WrapperNewsList>
            {news.map((el, index) => (
              <div
                key={el.id}
                className={el.isEnable ? 'news-item' : 'news-item-disabled'}
                draggable
                onDragStart={(e) => {
                  refDragItem.current = index
                }}
                onDragEnter={(e) => {
                  refDragOverItem.current = index
                }}
                onDragEnd={() => {
                  handleSort()
                }}
                onDragOver={(e) => {
                  e.preventDefault()
                }}
              >
                <div className="icons">
                  <button
                    className='btn-edit'
                    onClick={() => {
                      setNewsSelected(el)
                      toggleEditModal()
                    }}
                    title="Editar destaque"
                  >
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </button>
                  <button
                    className='btn-remove'
                    onClick={() => {
                      setNewsSelected(el)
                      toggleDeleteModal()
                    }}
                    title="Remover destaque"
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                  <input
                    title={el.isEnable ? 'Desativar destaque' : 'Ativar destaque'}
                    className='btn-enable'
                    type="checkbox"
                    checked={el.isEnable}
                    onChange={() => {
                      setNewsSelected(el)
                      toggleEnableModal()
                    }}
                  />
                </div>
                <img
                  src={el.imageUrlData}
                  alt="preview da imagen"
                  className="news-item__img"
                  loading="lazy"
                />
                <div className="text" dangerouslySetInnerHTML={{ __html: el.description }}></div>
              </div>
            ))}
          </WrapperNewsList>
        )}
      </WrapperContent>
      <ModalWrapper ref={refAddModal}>
        {isOpenAddModal && (
          <AddNewsModal
            toggleModal={toggleAddModal}
            listLength={news.length}
            getNews={() => {
              getNews()
            }}
          />
        )}
      </ModalWrapper>

      <ModalWrapper ref={refEditModal}>
        {isOpenEditModal && newsSelected && (
          <EditNewsModal
            toggleModal={toggleEditModal}
            newsSelected={newsSelected}
            getNews={() => {
              getNews()
            }}
          />
        )}
      </ModalWrapper>

      <ModalWrapper ref={refDeleteModal}>
        {isOpenDeleteModal && newsSelected && (
          <DeleteNewsModal
            toggleModal={toggleDeleteModal}
            newsSelected={newsSelected}
            getNews={() => {
              getNews()
            }}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refEnableModal}>
        {isOpenEnableModal && newsSelected && (
          <EnableNewsModal
            newsSelected={newsSelected}
            toggleModal={toggleEnableModal}
            updateData={() => {
              getNews()
            }}
          />
        )}
      </ModalWrapper>
    </AdmLayout>
  )
}
