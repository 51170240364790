import { Controller, useFormContext } from 'react-hook-form'
import { AttributeModel } from '../../entities'
import { IMaskInput } from 'react-imask'

import { ErrorMessage, FormInputIp } from './styles'

type Props = {
  attribute: AttributeModel
}

const IpField = ({ attribute }: Props) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()
  const isRequired = attribute.required ?? false

  return (
    <Controller
      name={attribute.id.toString()}
      control={control}
      rules={{ required: isRequired }}
      render={({ field: { onChange } }) => (
        <FormInputIp>
          <label htmlFor={attribute.id.toString()}>{attribute.question}</label>
          <IMaskInput
            mask={[
              {
                mask: 'IP{.}`IP{.}`IP{.}`IP',
                blocks: {
                  IP: {
                    mask: [
                      {
                        mask: '\\0'
                      },
                      {
                        mask: Number,
                        scale: 0,
                        min: 0,
                        max: 255
                      }
                    ]
                  }
                }
              },
              {
                mask: 'HEX{:}HEX{:}HEX{:}HEX{:}HEX{:}HEX{:}HEX{:}HEX',
                blocks: {
                  HEX: /^[0-9a-f]{0,4}$/i
                }
              }
            ]}
            onAccept={(value) => {
              onChange(value)
            }}
            unmask={true}
          />
          {errors[attribute.id.toString()]?.type === 'required' && (
            <ErrorMessage>* Campo Obrigatório</ErrorMessage>
          )}
        </FormInputIp>
      )}
    />
  )
}

export default IpField
