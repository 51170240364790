import { useContext } from 'react'
import { ThemeContext } from 'styled-components'

export function useGetColorFromTicketStatus () {
  const theme = useContext(ThemeContext)

  function getColor (status: string) {
    switch (status) {
      case 'Encerrado':
        return theme?.colors.black
      case 'Em atendimento':
        return theme?.colors.fuchsia._600
      case 'Agendado':
        return theme?.colors.yellow._700
      case 'Resolvido':
        return theme?.colors.green._600
      case 'Pendente Usuário':
        return theme?.colors.red._700
      case 'Pendente Fornecedor':
        return theme?.colors.red._700
      case 'Pendente Transporte':
        return theme?.colors.red._700
      case 'Pendente Aprovação':
        return theme?.colors.red._700
      case 'Direcionado':
        return theme?.colors.blue._200
      default:
        return theme?.colors.gray._600
    }
  }

  return {
    getColor
  }
}
