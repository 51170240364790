import styled from 'styled-components'

export const ColorWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // Class from React-Colorful lib
  .react-colorful {
    width: 100%;
    height: 15rem;
    border-radius: 0.5rem;
  }
`
export const BtnGroup = styled.div<{ $color: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: fit-content;
  /* max-width: 15rem; */
  height: 2.3rem;
  padding: 0 0.5rem 0 1rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.gray._400};

  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray._500};
  line-height: 1.5;
  background-clip: padding-box;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.gray._500};
    background-color: ${({ theme }) => theme.colors.gray._100};
    appearance: none;
    outline: 0;
  }

  .btn-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: ${({ theme }) => theme.colors.gray._500};

    span {
      width: 1rem;
      height: 1rem;
      background-color: ${(props) => props.$color};
      margin-right: 1.25rem;
    }
  }

  .btn-menu {
    border: none;
    background-color: transparent;
    height: 100%;
    width: fit-content;
    min-width: 2rem;
    margin-left: 2rem;

    &:hover {
      cursor: pointer;
    }
    svg {
      color: ${({ theme }) => theme.colors.gray._700};
    }
  }
`
