import { useState } from 'react'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { ModalContent } from 'components'
import { FormModel } from '../../entities'
import { editForm } from 'services/api'

import { ModalBodyWarning } from 'components/Modal/styles'

type Props = {
  toggleModal: () => void
  updateData: () => void
  formSelected: FormModel
}

const EnableFormModal = ({ toggleModal, formSelected, updateData }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const dto: FormModel = {
        ...formSelected,
        isEnable: !formSelected?.isEnable
      }
      const response = await editForm(formSelected.id, dto)
      if (!response) {
        throw new Error()
      }
      updateData()
    } catch (error: any) {
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥,  ocorreu um erro ao editar o formulário')
    } finally {
      setIsLoading(false)
      toggleModal()
    }
  }

  return (
    <ModalContent
      title={formSelected?.isEnable ? 'Desativar formulário' : 'Ativar formulário'}
      handleCloseModal={toggleModal}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      btnText="Confirmar"
      btnColor='#ea580c'
    >
      <ModalBodyWarning>
        <div className="question">
          <span className="icon">
            <FontAwesomeIcon icon={faExclamation} />
          </span>
          {formSelected?.isEnable ? (
            <p>Deseja desativar o formulário: </p>
          ) : (
            <p>Deseja ativar o formulário: </p>
          )}
        </div>
        <p className="name">{formSelected?.title}</p>
        {formSelected?.isEnable ? (
          <span className="warning-msg">
            <FontAwesomeIcon icon={faTriangleExclamation} />
            <p>
              Ao desativar este formulário não poderá ser visualizado!
            </p>
          </span>
        ) : (
          <span className="warning-msg">
            <FontAwesomeIcon icon={faTriangleExclamation} />
            <p>Ao ativar este formulário será visualizado!</p>
          </span>
        )}
      </ModalBodyWarning>
    </ModalContent>
  )
}

export default EnableFormModal
