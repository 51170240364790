import { KeyboardEvent, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { Spinner, ModalContent, CategoryInfoButton } from 'components'
import { RadioField, SelectField } from 'components/Fields'
import { CategoryModel, ICategory } from '../../entities'
import { getCategoryByName } from 'services/connector'
import { OptionSelect } from 'components/Select/Select'

import { ErrorMessage, FormInputSearch } from 'components/Fields/styles'
import { WrapperCategoryModal } from './styles'

const statusOptions: OptionSelect[] = [
  { label: 'Aberto', value: 'OP' },
  { label: 'Direcionado', value: 'DIR' }
]

type Props = {
  toggleModal: () => void
  fieldName?: string | null
  optIndex?: number | null
}

const CategoryModal = ({ toggleModal, fieldName = null, optIndex = null }: Props) => {
  const {
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    getValues,
    resetField
  } = useFormContext<any>()

  const [dataSelected, setDataSelected] = useState<Pick<
  ICategory,
  'name' | 'type' | 'categoryId' | 'group' | 'status'
  > | null>(null)
  const [valueToSearch, setValueToSearch] = useState('')
  const [dataFound, setDataFound] = useState<CategoryModel[] | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const categoryId =
    fieldName && optIndex !== null ? `${fieldName}.${optIndex}.categoryId` : 'categoryId'
  const type = fieldName && optIndex !== null ? `${fieldName}.${optIndex}.type` : 'type'
  const group = fieldName && optIndex !== null ? `${fieldName}.${optIndex}.group` : 'group'
  const categoryName =
    fieldName && optIndex !== null ? `${fieldName}.${optIndex}.categoryName` : 'categoryName'
  const openingStatus =
    fieldName && optIndex !== null ? `${fieldName}.${optIndex}.openingStatus` : 'openingStatus'
  const errorCategory =
    fieldName && errors[fieldName] ? (errors as any)[fieldName][optIndex as number] : null

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Delete') {
      setDataFound([])
      setValueToSearch('')
      setDataSelected(null)
    }
  }

  const handleSearchData = async () => {
    try {
      setIsLoading(true)
      if (!getValues(type)) {
        setError(type, { type: 'required', message: '* Campo obrigatório' })
        setIsLoading(false)
        return
      }
      if (valueToSearch === '') {
        setError(categoryName, { type: 'required', message: '* Campo obrigatório' })
        setIsLoading(false)
        return
      }
      const response = await getCategoryByName(getValues(type) === 'Requisição', valueToSearch)
      if (!response) {
        throw new Error()
      }

      setDataFound(response)
    } catch (error) {
      toast.error('Ops 😥, ocorreu um erro ao adicionar um novo formulário')
    } finally {
      setIsLoading(false)
    }
  }

  const handleSelectCategory = (category: CategoryModel) => {
    setDataSelected({
      categoryId: category.id.toString(),
      name: category.name,
      type: category.type,
      group: category.group,
      status: getValues(openingStatus)
    })
    setValueToSearch('')
    clearErrors(categoryName)
  }

  const clearData = () => {
    setDataFound(null)
    setValueToSearch('')
    setDataSelected(null)
  }

  const handleSaveCategory = () => {
    if (!getValues(openingStatus)) {
      setError(openingStatus, { type: 'required', message: '* Campo obrigatório' })
      return
    }
    if (!dataSelected) {
      setError(categoryName, { type: 'required', message: '* Campo obrigatório' })
      return
    }

    setValue(categoryName, dataSelected.name)
    setValue(categoryId, dataSelected.categoryId)
    setValue(group, dataSelected.group)
    setValue(type, dataSelected.type)
    toggleModal()
  }

  const handleCloseModal = () => {
    clearData()
    clearErrors(categoryName)
    clearErrors(type)
    clearErrors(openingStatus)
    resetField(categoryName)
    resetField(type)
    resetField(openingStatus)
    toggleModal()
  }

  return (
    <ModalContent
      title="Procurar categoria"
      handleCloseModal={handleCloseModal}
      handleSubmit={null}
      isLoading={false}
      btn2Text="Salvar"
      handleOpenModal={handleSaveCategory}
    >
      <WrapperCategoryModal>
        <SelectField
          attribute={{
            id: openingStatus,
            question: 'Selecione o status do chamado',
            required: true,
            optionsSelectElement: []
          }}
          options={statusOptions}
          menuPlacement="bottom"
          errorCategory={errorCategory?.openingStatus?.message.toString()}
        />
        <RadioField
          attribute={{
            id: type,
            question: 'Escolha o tipo da categoria?',
            optionsSelectElement: [
              {
                id: crypto.randomUUID(),
                title: 'Requisição',
                value: 'Requisição',
                attributes: [],
                weight: 1,
                propertyId: null,
                categoryId: null,
                type: null,
                group: null,
                categoryName: null,
                openingStatus: null
              },
              {
                id: crypto.randomUUID(),
                title: 'Incidente',
                value: 'Incidente',
                attributes: [],
                weight: 2,
                propertyId: null,
                categoryId: null,
                type: null,
                group: null,
                categoryName: null,
                openingStatus: null
              }
            ],
            required: true,
            isHiddenToVip: false,
            type: 'radio'
          }}
          handleOptionSelected={() => {}}
          errorCategory={errorCategory?.type?.message.toString()}
        />

        <div className="search-category">
          <FormInputSearch className="input-category">
            <div className="input-search">
              <label htmlFor="categoryName"></label>
              <div className="input-search__loading">
                <input
                  type="search"
                  autoComplete="off"
                  placeholder="Digite o nome da categoria"
                  value={dataSelected?.name ?? valueToSearch}
                  onChange={(e) => {
                    setValueToSearch(e.target.value)
                    clearErrors(categoryName)
                  }}
                  onKeyDown={(e) => {
                    handleKeyDown(e)
                  }}
                />
                {isLoading && (
                  <span className="loading">
                    {' '}
                    <Spinner size={20} marginTop="1rem" />
                  </span>
                )}
                {!isLoading && dataSelected && (
                  <span className="clear-icon">
                    {' '}
                    <FontAwesomeIcon
                      icon={faXmark}
                      onClick={() => {
                        clearData()
                      }}
                    />
                  </span>
                )}
              </div>
            </div>
            {!isLoading && dataFound && dataFound.length > 0 && valueToSearch && (
              <div className="input-search-list">
                <ul>
                  {dataFound.map((item: CategoryModel, index: number) => (
                    <li
                      key={index}
                      onClick={() => {
                        handleSelectCategory(item)
                      }}
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {!isLoading && dataFound && dataFound.length === 0 && valueToSearch && (
              <div className="input-search-list">
                <div>Nenhuma categoria encontrada</div>
              </div>
            )}
          </FormInputSearch>
          {!isLoading && fieldName && optIndex !== null && errorCategory?.categoryName && (
            <ErrorMessage>{errorCategory?.categoryName?.message.toString()}</ErrorMessage>
          )}

          {!isLoading && errors.categoryName && !(fieldName && optIndex) && (
            <ErrorMessage>{errors.categoryName?.message?.toString()}</ErrorMessage>
          )}
        </div>
        <button
          type="button"
          className="btn-search"
          onClick={handleSearchData}
          disabled={isLoading}
        >
          Pesquisar
        </button>

        {dataSelected && (
          <CategoryInfoButton
            categorySelected={dataSelected}
            toggleCategoryModal={() => {}}
            hasButton={false}
          />
        )}
      </WrapperCategoryModal>
    </ModalContent>
  )
}

export default CategoryModal
