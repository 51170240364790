import { getFileUrl } from 'services/fileManager'

const getUrlFile = async (fileData: File) => {
  const formData = new FormData()
  formData.append('files', fileData)
  const response = await getFileUrl(formData)
  return response
}

export const setFileField = async (data: Record<string, string | File>) => {
  let attachmentsCa: Array<{ file_url: string, token: string }> = []

  for (const value of Object.values(data)) {
    if (value instanceof File) {
      const respFileManager = await getUrlFile(value)
      if (!respFileManager.data) {
        throw new Error()
      }
      attachmentsCa = [
        {
          file_url: respFileManager.data[0].name,
          token: ''
        }
      ]
    }
  }
  return attachmentsCa
}
