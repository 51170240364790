/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  useGetTickets,
  useGetColorFromTicketStatus,
  useGetTicketSurveysAnswered
} from '../../hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons'
import { TicketSurveyModal, BtnSurveyTicket } from 'components'
import { IPartialTicket } from '../../entities'

import { ContainerTickets, ContainerTitle, TicketStatus } from './styles'
import { ModalWrapper } from 'pages/Admin/styles'

const TicketList = () => {
  const { ticketList, isLoadingTickets, getTicketList } = useGetTickets()
  const { getColor } = useGetColorFromTicketStatus()
  const { getSurveyAnsweredList, surveysAnswered } = useGetTicketSurveysAnswered()

  const refSurveyModal = useRef<HTMLDialogElement>(null)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [ticketSelected, setTicketSelected] = useState<IPartialTicket | null>(null)

  const toggleSurveyModal = (ticket: IPartialTicket | null = null) => {
    if (refSurveyModal.current) {
      if (isOpenModal) {
        setTicketSelected(null)
        setIsOpenModal(false)
        refSurveyModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setTicketSelected(ticket)
      setIsOpenModal(true)
      refSurveyModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  useEffect(() => {
    getTicketList({ page: 0, limit: 4 })
  }, [])

  useEffect(() => {
    if (ticketList.length > 0) {
      getSurveyAnsweredList(ticketList)
    }
  }, [ticketList])

  return (
    <ContainerTickets>
      <ContainerTitle>
        {'ÚLTIMOS CHAMADOS'}
        <span />
      </ContainerTitle>

      {isLoadingTickets && (
        <div>
          <span>Carregando os chamados...</span>
        </div>
      )}

      {!isLoadingTickets && ticketList.length === 0 && (
        <div>
          <span>Nenhum chamado encontrado!</span>
        </div>
      )}

      {!isLoadingTickets &&
        ticketList.length > 0 &&
        ticketList.map((ticket) => (
          <div key={ticket.ticketID} className="ticket-item">
            <div className="ticket-item-info">
              <span>{`N° ${ticket.ticketID}`}</span>
              <span>{ticket.title}</span>
              <TicketStatus $statusColor={getColor(ticket.status)}>{ticket.status}</TicketStatus>
            </div>
            <div className="ticket_icons">
              <BtnSurveyTicket
                ticket={ticket}
                toggleModal={toggleSurveyModal}
                surveysAnswered={surveysAnswered}
              />
              <Link className="arrow-icon" to={`/ticket/detail/${ticket.ticketID}`}>
                <FontAwesomeIcon icon={faCircleChevronRight} />
              </Link>
            </div>
          </div>
        ))}

      <ModalWrapper ref={refSurveyModal} $width="70%">
        {isOpenModal && ticketSelected && (
          <TicketSurveyModal
            toggleModal={toggleSurveyModal}
            ticket={ticketSelected}
            updateData={() => {
              getTicketList({ page: 0, limit: 4 })
            }}
          />
        )}
      </ModalWrapper>
    </ContainerTickets>
  )
}

export default TicketList
