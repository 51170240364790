import { useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { SurveyQuestionModel, SurveyTemplateModel } from '../../entities'
import ModalContent from 'components/Modal/ModalContent'
import { ModalBodyForm } from 'components/Modal/styles'
import { SurveyFieldList, TextareaField, TextField } from 'components/Fields'
import { ModalWrapper } from 'pages/Admin/styles'
import PreviewSurveyModal from './PreviewSurveyModal'
import {
  setQuestionsToShow,
  setQuestionsToSend,
  setOptionsFromQuestions
} from './utils'
import { ISurveyFormInput } from './CreateSurveyModal'
import { editSurvey } from 'services/api'

type Props = {
  toggleModal: () => void
  updateData: () => void
  surveySelected: SurveyTemplateModel
}

const EditSurveyModal = ({ toggleModal, updateData, surveySelected }: Props) => {
  const defaultValues = {
    title: surveySelected.title,
    identifier: surveySelected.identifier,
    description: surveySelected.description,
    surveyQuestions: setOptionsFromQuestions(surveySelected.surveyQuestions),
    isEnable: surveySelected.isEnable
  }
  const methods = useForm<ISurveyFormInput>({ defaultValues })

  const refPreviewModal = useRef<HTMLDialogElement>(null)
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [previewContent, setPreviewContent] = useState<SurveyTemplateModel | null>(null)

  const togglePreviewModal = async () => {
    if (refPreviewModal.current) {
      if (isOpenPreviewModal) {
        setIsOpenPreviewModal(false)
        refPreviewModal?.current.close()
        return
      }
      const result = setQuestionsToShow({
        question: methods.getValues('surveyQuestions'),
        questionsReused: []
      })
      setPreviewContent({
        id: 1,
        title: methods.getValues('title'),
        identifier: 'feedback',
        isEnable: true,
        surveyQuestions: result ?? [],
        description: methods.getValues('description')
      })
      setIsOpenPreviewModal(true)
      refPreviewModal?.current.showModal()
    }
  }

  const checkDataChanges = (dto: any): boolean => {
    const originalData = {
      title: surveySelected.title,
      identifier: surveySelected.identifier,
      description: surveySelected.description,
      surveyQuestions: surveySelected.surveyQuestions,
      isEnable: surveySelected.isEnable
    }

    return JSON.stringify(originalData) === JSON.stringify(dto)
  }

  const onSubmit: SubmitHandler<ISurveyFormInput> = async (
    data: ISurveyFormInput
  ): Promise<void> => {
    try {
      setIsLoading(true)
      const dto: SurveyTemplateModel = {
        ...surveySelected,
        title: data.title,
        identifier: data.identifier,
        description: data.description,
        surveyQuestions: setQuestionsToSend(data.surveyQuestions) as SurveyQuestionModel[],
        isEnable: data.isEnable
      }

      const isEqual = checkDataChanges(dto)

      if (isEqual) {
        toast.warn('Nada a ser editado!')
        return
      }

      const response = await editSurvey(dto)
      if (!response) {
        throw new Error()
      }
      updateData()
    } catch (error: any) {
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao editar a pesquisa')
    } finally {
      setIsLoading(false)
      methods.reset()
      toggleModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Editar pesquisa"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
        handleOpenModal={togglePreviewModal}
        btn2Text="Visualizar"
      >
        <ModalBodyForm>
          <TextField
            attribute={{
              id: 'title',
              question: 'Digite o título da pesquisa:',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false
            }}
          />
          <TextareaField
            attribute={{
              id: 'description',
              question: 'Descrição da pesquisa:',
              optionsSelectElement: [],
              required: false,
              isHiddenToVip: false
            }}
          />

          {/* <SelectField
            attribute={{
              id: 'identifier',
              question: 'Selecione o destino ao qual estará direcionada a pesquisa:',
              optionsSelectElement: [],
              required: false,
              isHiddenToVip: false
            }}
            defaultValue={[identifierOptions[0]] as OptionSelect[]}
            options={identifierOptions}
            menuPlacement={'bottom'}
            customSelectStyles={customSelectStyles}
          /> */}
          <SurveyFieldList<ISurveyFormInput> fieldName="surveyQuestions" />
        </ModalBodyForm>
      </ModalContent>
      <ModalWrapper ref={refPreviewModal} $width="70%">
        {isOpenPreviewModal && previewContent && (
          <PreviewSurveyModal toggleModal={togglePreviewModal} survey={previewContent} />
        )}
      </ModalWrapper>
    </FormProvider>
  )
}

export default EditSurveyModal
