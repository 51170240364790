import { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import {
  AddAttributeModal,
  AddButton,
  AdmLayout,
  DeleteAttributedModal,
  DropdownForm,
  EditAttributeModal,
  SearchAttribute,
  Spinner
} from 'components'
import { AttributeModel } from '../../../entities'
import { useGetAttributesWithPagination } from 'hooks'

import {
  EmptyListMessage,
  ModalWrapper,
  TitlePage,
  WrapperAttributeList,
  WrapperBtnSearchForm,
  WrapperContent
} from '../styles'

export const AttributesAdmin = () => {
  const { attributes, isLoadingAttributes, getAttributes, setAttributes } =
    useGetAttributesWithPagination()
  const refAddModal = useRef<HTMLDialogElement>(null)
  const refEditModal = useRef<HTMLDialogElement>(null)
  const refDeleteModal = useRef<HTMLDialogElement>(null)
  const [isOpenAddModal, setIsOpenAddModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [attributeSelected, setAttributeSelected] = useState<AttributeModel | null>(null)
  const [isSearchData, setIsSearchData] = useState(false)

  const toggleAddModal = () => {
    if (refAddModal.current) {
      if (isOpenAddModal) {
        setIsOpenAddModal(false)
        refAddModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenAddModal(true)
      refAddModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const toggleEditModal = () => {
    if (refEditModal.current) {
      if (isOpenEditModal) {
        setIsOpenEditModal(false)
        refEditModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenEditModal(true)
      refEditModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const toggleDeleteModal = () => {
    if (refDeleteModal.current) {
      if (isOpenDeleteModal) {
        setIsOpenDeleteModal(false)
        refDeleteModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenDeleteModal(true)
      refDeleteModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const handleUpdateData = () => {
    if (isSearchData) {
      window.location.reload()
    } else {
      getAttributes()
    }
  }

  useEffect(() => {
    getAttributes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdmLayout title="Administração da perguntas">
      <WrapperContent>
        <TitlePage>Perguntas registradas</TitlePage>
        <p className="description-label">
          Observação: Todas as perguntas podem ser compartilhadas entre os formulários, o que
          significa que ao editar e/ou remover alguma pergunta, todos os formulários que contém essa
          pergunta serão afetados.
        </p>
        <WrapperBtnSearchForm $isSearchData={isSearchData}>
          {!isSearchData && <AddButton text="Criar pergunta" onClick={toggleAddModal} />}
          <SearchAttribute
            onDataSelected={(data) => {
              setAttributes([data])
              setIsSearchData(true)
            }}
            updateData={() => {
              setIsSearchData(false)
              getAttributes()
            }}
          />
        </WrapperBtnSearchForm>
        {/* <AddButton text="Adicionar nova pergunta" onClick={toggleAddModal} /> */}
        <WrapperAttributeList>
          {isLoadingAttributes && <Spinner />}
          {!isLoadingAttributes && attributes.length === 0 && (
            <EmptyListMessage>
              <p>Nenhuma pergunta encontrada!</p>
            </EmptyListMessage>
          )}
          {!isLoadingAttributes && attributes.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th className="title">Pergunta</th>
                  <th className="info">Fomulários</th>
                  <th className="action">Ação</th>
                </tr>
              </thead>
              <tbody>
                {attributes.map((att: AttributeModel) => (
                  <tr key={att.id}>
                    <td className="cl-title">{att.question}</td>
                    <td className="cl-info">{att.forms && <DropdownForm forms={att.forms} />}</td>
                    <td className="cl-action">
                      <button
                        className='edit'
                        onClick={() => {
                          setAttributeSelected(att)
                          toggleEditModal()
                        }}
                        title="Editar"
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </button>
                      <button
                        className='remove'
                        onClick={() => {
                          setAttributeSelected(att)
                          toggleDeleteModal()
                        }}
                        title="Remover"
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </WrapperAttributeList>
        {/* {!isLoadingAttributes && attributes.length > 0 && !isSearchData && (
          <Pagination
            currentPage={pagination.page + 1}
            onNextPage={() => {
              getAttributes(pagination.page + 1)
            }}
            onPreviousPage={() => {
              getAttributes(pagination.page - 1)
            }}
            hasNextPage={pagination.next}
            hasPreviousPage={pagination.prev}
          />
        )} */}
      </WrapperContent>
      <ModalWrapper ref={refAddModal} $width={'80%'}>
        {isOpenAddModal && (
          <AddAttributeModal
            toggleModal={toggleAddModal}
            updateData={() => {
              handleUpdateData()
            }}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refEditModal} $width={'80%'}>
        {isOpenEditModal && attributeSelected && (
          <EditAttributeModal
            toggleModal={toggleEditModal}
            attributeSelected={attributeSelected}
            updateData={() => {
              handleUpdateData()
            }}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refDeleteModal}>
        {isOpenDeleteModal && attributeSelected && (
          <DeleteAttributedModal
            toggleModal={toggleDeleteModal}
            attributeSelected={attributeSelected}
            updateData={() => {
              handleUpdateData()
            }}
          />
        )}
      </ModalWrapper>
    </AdmLayout>
  )
}
