/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ContainerLoading, Layout, TicketListAll } from '../../components'
import { useGetTickets } from '../../hooks'
import { AuthContext } from 'contexts/auth'
import { getTicket } from 'services/connector'
import { IPartialTicket } from '../../entities'

import { ContainerConsultTicket } from './styles'

export const ConsultTicketList = () => {
  const { isLoadingTickets, ticketList, getTicketList, pagination } = useGetTickets()

  const { logout } = useContext(AuthContext)
  const navigate = useNavigate()
  const [ticketStatus, setTicketStatus] = useState('')
  const [ticketById, setTicketById] = useState<IPartialTicket[] | null>(null)
  const [isLoadingTicketById, setLoadingTicketById] = useState(false)

  const getTicketDetails = async (ticketId: string): Promise<void> => {
    setLoadingTicketById(true)
    try {
      const userLogin = JSON.parse(localStorage.getItem('user') as string).userLogin

      if (!userLogin) {
        toast.error(
          'Ops 😥, houve um erro com os dados do usuário, por favor fazer login novamente'
        )
        setTimeout(() => {
          logout()
        }, 3000)
        return
      }

      const response = await getTicket(userLogin, ticketId)

      if (!response) {
        throw new Error()
      }

      navigate(`/ticket/detail/${ticketId}`)
    } catch (error: any) {
      setLoadingTicketById(false)
      setTicketById([])
      if (
        error?.response?.data?.err === 'Chamado não encontrado' ||
        error?.response?.data?.err === 'Usuário não é o solitante do chamado'
      ) {
        toast.error('Ops 😥, Chamado não encontrado! ')
      } else {
        toast.error('Ops 😥, ocorreu um erro ao recuperar o chamado ')
      }
    }
  }

  const handleSearch = async (value: string) => {
    if (value) {
      await getTicketDetails(value)
    }
  }

  useEffect(() => {
    getTicketList({ page: 0 })
  }, [])

  return (
    <Layout title="Consulta de chamados">
      <ContainerConsultTicket $isLoadingTickets={isLoadingTicketById || isLoadingTickets}>
        {isLoadingTickets || isLoadingTicketById ? (
          <ContainerLoading />
        ) : (
          <TicketListAll
            ticketList={ticketById ?? ticketList}
            handleSearch={handleSearch}
            setTicketById={setTicketById}
            getTicketList={getTicketList}
              pagination={pagination}
              ticketStatus={ticketStatus}
              setTicketStatus={setTicketStatus}
          />
        )}
      </ContainerConsultTicket>
    </Layout>
  )
}
