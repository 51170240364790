import styled from 'styled-components'

export const WrapperSurveyForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5rem;
  line-height: 1.5;

  .survey_message_error {
    text-align: center;
  }
  .survey_description {
    font-size: 1.15rem;
    color: ${({ theme }) => theme.colors.blue._800};
    font-weight: bold;
  }

  .survey_form {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
  label {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.gray._700};
  }

  textarea {
    margin-top: 0.5rem;
  }
`
