import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'
import { Button, ModalContent } from '..'
import { ModalBodyWarning } from 'components/Modal/styles'

type Props = {
  toggleModal: () => void
  toggleNextModal: () => void
  cardName: string
  formName: string
}

const InfoFormModal = ({
  toggleModal,
  cardName,
  formName,
  toggleNextModal
}: Props) => {
  return (
    <ModalContent
      title="Desvincular formulário"
      handleCloseModal={toggleModal}
      handleSubmit={null}
      isLoading={false}
    >
      <ModalBodyWarning>
        <span className="icon">
          <FontAwesomeIcon icon={faExclamation} />
        </span>
        <p className="info-form-text">
          O card <b>{cardName}</b> possui o seguinte formulário: <b>{formName}</b>, para poder criar
          um card neste nível é necessário desvincular o formulário existente do card.
        </p>
        <p className="info-form-question">
          Deseja desvincular o formulário para poder criar um subcard?
        </p>
        <div className="info-form-btn">
          <Button onClick={toggleModal} text="Não, cancelar" color="#cf1f3f" />
          <Button
            onClick={() => {
              toggleNextModal()
              toggleModal()
            }}
            text="Sim, continuar"
            color="#008000"
          />
        </div>
      </ModalBodyWarning>
    </ModalContent>
  )
}

export default InfoFormModal
