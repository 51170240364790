export const themes = {
  colors: {
    gray: {
      _50: ' #ebf9ff',
      _100: '#f1f1f1',
      _200: '#e2e8f0',
      _300: '#E5E6E9',
      _400: '#d2d2d2',
      _450: '#ababab',
      _500: '#707070',
      _600: '#696969',
      _700: '#333',
      _800: '#27272a;',
      _900: '#00000029'
    },
    white: '#FFFFFF',
    black: '#000000',
    blue: {
      _50: '#a0d1e6',
      _100: '#69c9fc',
      _200: '#00A8FF',
      _300: '#238ee7',
      _400: '#0095D7',
      _500: '#0372ad',
      _600: '#1F7AC9',
      _800: '#002356'
    },
    fuchsia: {
      _600: '#993399'
    },
    purple: {
      _300: '#4155eb'
    },
    yellow: {
      _100: '#ecc91b',
      _200: '#FFC21A',
      _700: '#FFA500',
      _800: '#FF9710'
    },
    green: {
      _400: '#49bc20',
      _600: '#008000'
    },
    red: {
      _100: '#fee2e2',
      _500: '#cf1f3f',
      _600: '#b52e2e',
      _700: '#FF0000'
    },
    orange: {
      _50: '#fff7ed',
      _100: '#ffedd5',
      _600: '#ea580c',
      _700: '#c2410c'
    }
  }
}
