import styled from 'styled-components'

export const WrapperStart = styled.div`
  display: flex;
  gap: 1rem;
  input[type='radio'] {
    display: none;
  }
  .star {
    cursor: pointer;
    font-size: 2rem;
    margin: 5px;
  }
`
