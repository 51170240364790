import { useState } from 'react'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'
import { ModalContent } from 'components'
import { SurveyTemplateModel } from '../../entities'

import { ModalBodyDelete } from 'components/Modal/styles'
import { ErrorMessage } from 'components/CardAdmin/styles'
import { editSurvey } from 'services/api'

type Props = {
  toggleModal: () => void
  updateData: () => void
  surveySelected: SurveyTemplateModel
}

const EnableSurveyModal = ({ toggleModal, surveySelected, updateData }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isConfirm, setIsConfirm] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleSubmit = async (): Promise<void> => {
    if (!isConfirm) {
      setErrorMessage('* Campo obrigatório')
      return
    }
    try {
      setIsLoading(true)
      const dto = {
        ...surveySelected,
        isEnable: !surveySelected.isEnable
      }
      const response = await editSurvey(dto)
      if (!response) {
        throw new Error()
      }
      updateData()
    } catch (error: any) {
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao editar a pesquisa!')
    } finally {
      setIsLoading(false)
      toggleModal()
    }
  }

  return (
    <ModalContent
      title={surveySelected?.isEnable ? 'Desativar pesquisa' : 'Ativar pesquisa'}
      handleCloseModal={toggleModal}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      btnText="Confirmar"
      btnColor="#ea580c"
    >
      <ModalBodyDelete>
        <div className="question">
          <span className="icon">
            <FontAwesomeIcon icon={faExclamation} />
          </span>
          {surveySelected?.isEnable ? (
            <p>Deseja desativar a pesquisa: </p>
          ) : (
            <p>Deseja ativar a pesquisa: </p>
          )}
        </div>
        <p className="admName">{surveySelected?.title}</p>
        {surveySelected?.isEnable ? (
          <span className="warning-msg">
            <p>Ao desativar esta pesquisa não poderá ser visualizada!</p>
          </span>
        ) : (
          <span className="warning-msg">
            <p>Só é possível ter uma pesquisa por vez ativada!</p>
          </span>
        )}
        <div className="confirm-action">
          <span className="confirm-msg">
            <input
              type="checkbox"
              onChange={() => {
                setIsConfirm(!isConfirm)
                setErrorMessage('')
              }}
            />
            <p>Confirmo que li e estou ciente.</p>
          </span>
          {errorMessage && <ErrorMessage $fontSize={'0.75rem'}>{errorMessage}</ErrorMessage>}
        </div>
      </ModalBodyDelete>
    </ModalContent>
  )
}

export default EnableSurveyModal
