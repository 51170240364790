import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { CardModel } from '../../entities'
import { CardIcon, CardItem, CardLabelColor } from './styles'

type Props = {
  card: CardModel
  url: string
  isCarousel?: boolean
}

const Card = ({ card, url, isCarousel = false }: Props) => {
  return (
    <Link to={url} style={{ textDecoration: 'none' }}>
      <CardItem $colorLabel={card.color} $isCarousel={isCarousel}>
        <CardLabelColor $colorLabel={card.color} />
        <div className="card-information">
          <CardIcon>
            <FontAwesomeIcon icon={(card.icon as IconProp) ?? 'fa-solid fa-gear'} />
          </CardIcon>
          <p className="card-title">{card.name}</p>
        </div>
      </CardItem>
    </Link>
  )
}

export default Card
