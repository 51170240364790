/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { CardModel } from 'entities'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Breadcrumb } from './styles'

type ItemSelectedProps = {
  list: [] | CardModel[]
  idL2: string | number | null | undefined
  idL1: string | number | null | undefined
  id: string | number | undefined
}

const getCardsNameSelected = ({ list, idL1, id, idL2 }: ItemSelectedProps): any => {
  if (idL2) {
    const cardSelectedL1 = list.find((item) => item.id.toString() === idL1)
    const cardSelectedL2 = cardSelectedL1?.children.find((item) => item.id.toString() === idL2)
    const cardSelected = cardSelectedL2?.children.find((item) => item.id.toString() === id)
    return {
      cardName: { name: cardSelected?.name, value: id },
      cardNameL1: { name: cardSelectedL1?.name, value: idL1 },
      cardNameL2: { name: cardSelectedL2?.name, value: idL2 }
    }
  }

  if (idL1) {
    const cardSelectedL1 = list.find((item) => item.id.toString() === idL1)
    const cardSelected = cardSelectedL1?.children.find((item) => item.id.toString() === id)
    return {
      cardName: { name: cardSelected?.name, value: id },
      cardNameL1: { name: cardSelectedL1?.name, value: idL1 }
    }
  }
  const cardSelected = list.find((item) => item.id.toString() === id)

  if (!cardSelected) return { cardName: { name: 'Cards', value: 'admin/card' } }
  return {
    cardName: { name: cardSelected?.name, value: id }
  }
}

type Props = {
  cardList: CardModel[]
}

const BreadcrumbsAdm = ({ cardList }: Props) => {
  const location = useLocation()

  const { cardId, cardIdL1, cardIdL2 } = useParams()
  let currentName = 'Cards'
  let currentLink = '/admin/card'

  const { cardName, cardNameL1, cardNameL2 } = getCardsNameSelected({
    list: cardList,
    id: cardId,
    idL1: cardIdL1,
    idL2: cardIdL2
  })

  const crumbs = location.pathname
    .split('/')
    .filter((crumb) => crumb !== '')
    .slice(2)
    .map((crumb) => {
      currentLink += `/${crumb}`
      if (crumb === cardName?.value) {
        currentName = `${cardName.name}`
      }
      if (crumb === cardNameL1?.value) {
        currentName = `${cardNameL1.name}`
      }
      if (crumb === cardNameL2?.value) {
        currentName = `${cardNameL2.name}`
      }
      return (
        <div key={crumb} className="crumb">
          <Link to={currentLink}>{currentName}</Link>
        </div>
      )
    })
  return (
    <Breadcrumb>
      <div className="crumb">
        <Link to="/admin/card">Cards</Link>
      </div>
      {crumbs}
    </Breadcrumb>
  )
}

export default BreadcrumbsAdm
