import { useState } from 'react'
import { FormModel } from '../../entities'
import { WrapperDropdownForm } from './styles'

type Props = {
  forms: Array<Pick<FormModel, 'id' | 'title'>>
}

const DropdownForm = ({ forms }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <WrapperDropdownForm>
      {forms.length <= 3 && (
        <div className='wrapper-info'>
          {forms.map((form) => (
            <span key={form.id} className="title">
              {form.title}
            </span>
          ))}
        </div>
      )}
      {forms.length > 3 && !isOpen && (
        <div className='wrapper-info'>
          {forms.slice(0, 3).map((form) => (
            <span key={form.id} className="title">
              {form.title}
            </span>
          ))}
          <button
            type="button"
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            Ver mais
          </button>
        </div>
      )}
      {forms.length > 3 && isOpen && (
        <div className='wrapper-info'>
          {forms.map((form) => (
            <span key={form.id} className="title">
              {form.title}
            </span>
          ))}
          <button
            type="button"
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            Ver menos
          </button>
        </div>
      )}
    </WrapperDropdownForm>
  )
}

export default DropdownForm
