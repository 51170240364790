import { useState } from 'react'
import { toast } from 'react-toastify'
import { SurveyIdentifierType, SurveyTemplateModel } from '../entities'
import { getSurveyDataByIdentifier } from 'services/api'

export const useGetActiveSurveyByIdentifier = (identifier: SurveyIdentifierType) => {
  const [survey, setSurvey] = useState<SurveyTemplateModel | null>(null)
  const [isLoadingSurvey, setIsLoading] = useState(false)

  const getSurvey = async () => {
    try {
      setIsLoading(true)
      const response = await getSurveyDataByIdentifier(identifier)
      setSurvey(response)
    } catch (error: any) {
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao recuperar a pesquisa ')
    } finally {
      setIsLoading(false)
    }
  }

  return {
    survey,
    isLoadingSurvey,
    getSurvey
  }
}
