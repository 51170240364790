import { useState } from 'react'
import { toast } from 'react-toastify'
import { getForms } from 'services/api'
import { FormModel } from '../entities'

export const useGetFormList = () => {
  const [formList, setFormList] = useState<FormModel[]>([])
  const [isLoadingForms, setIsLoadingForms] = useState(false)
  const [pagination, setPagination] = useState({
    next: true,
    prev: false,
    page: 0
  })
  const getFormList = async (page = pagination.page) => {
    try {
      setIsLoadingForms(true)
      const response = await getForms({ page })

      if (!response) {
        throw new Error()
      }
      setFormList(response.data)
      setPagination({
        page,
        next: response.paginate.next,
        prev: response.paginate.prev
      })
    } catch (error: any) {
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao recuperar os formulários ')
    } finally {
      setIsLoadingForms(false)
    }
  }

  return {
    getFormList,
    formList,
    isLoadingForms,
    pagination,
    setFormList
  }
}
