import { useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { AttributeModel } from '../../entities'
import { DropdownForm, ModalContent } from 'components'
import AttributeField from '../Fields/AttributeFields/AttributeField'
import PreviewFormModal from 'components/FormAdmin/PreviewFormModal'
import { useSetExtraQuestions } from 'hooks'
import { editAttribute } from 'services/api'
import { setAttributeToShow } from 'components/FormAdmin/utils'

import { ModalWrapper } from 'pages/Admin/styles'
import { ModalBodyForm } from 'components/Modal/styles'

type Props = {
  toggleModal: () => void
  attributeSelected: AttributeModel
  updateData: () => void
}

const EditAttributeModal = ({ toggleModal, attributeSelected, updateData }: Props) => {
  const defaultValues = { ...attributeSelected }
  const methods = useForm<AttributeModel>({ defaultValues })

  const {
    optionListSelected,
    setOptionListSelected,
    handleCheckboxExtraQuestions,
    handleSelectExtraQuestions
  } = useSetExtraQuestions({ methods })

  const refPreviewModal = useRef<HTMLDialogElement>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false)
  const [previewContent, setPreviewContent] = useState<AttributeModel[] | null>(null)

  const togglePreviewModal = async () => {
    if (refPreviewModal.current) {
      if (isOpenPreviewModal) {
        setIsOpenPreviewModal(false)
        setOptionListSelected([])
        refPreviewModal?.current.close()
        return
      }

      const result = setAttributeToShow({
        attrsCreated: [{ ...methods.getValues() }],
        attrsReused: []
      })

      setPreviewContent(result ?? [])
      setIsOpenPreviewModal(true)
      refPreviewModal?.current.showModal()
    }
  }

  const checkDataChanges = (dto: any): boolean => {
    return JSON.stringify(attributeSelected) === JSON.stringify(dto)
  }

  const onSubmit: SubmitHandler<AttributeModel> = async (data: AttributeModel): Promise<void> => {
    setIsLoading(true)
    try {
      const isEqual = checkDataChanges(data)
      if (isEqual) {
        toast.warn('Nada a ser editado!')
        toggleModal()
        return
      }

      const response = await editAttribute(data as unknown as AttributeModel)
      if (!response) {
        throw new Error()
      }
      updateData()
    } catch (error: any) {
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao editar os dados da pergunta')
    } finally {
      setIsLoading(false)
      toggleModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Editar pergunta"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
        handleOpenModal={togglePreviewModal}
        btn2Text="Visualizar"
      >
        <ModalBodyForm>
          {attributeSelected?.forms && attributeSelected?.forms.length > 0 && (
            <span className="form-warning-msg">
              <strong>A edição desta pergunta, será editada nos seguintes formulários:</strong>
              {attributeSelected?.forms.length > 0 && (
                <DropdownForm forms={attributeSelected?.forms} />
              )}
            </span>
          )}
          <AttributeField<AttributeModel>
            attribute={methods.getValues()}
            question={'question'}
            type={'type'}
            positionOrder="positionOrder"
            isHiddenToVip="isHiddenToVip"
            isVisibleToDescription="isVisibleToDescription"
            required="required"
            isMoved={false} // HIDE MOVE BOTTOM
            optionsSelectElementFieldName={'optionsSelectElement'}
            firstLevel={true} // TO AVOID INFINITY CYCLE OF SELECT <-> SELECT
          />
        </ModalBodyForm>
      </ModalContent>
      <ModalWrapper ref={refPreviewModal}>
        {isOpenPreviewModal && previewContent && (
          <PreviewFormModal
            title="Visualização da pergunta"
            toggleModal={togglePreviewModal}
            attributes={previewContent}
            optionListSelected={optionListSelected}
            handleCheckboxExtraQuestions={handleCheckboxExtraQuestions}
            handleSelectExtraQuestions={handleSelectExtraQuestions}
          />
        )}
      </ModalWrapper>
    </FormProvider>
  )
}

export default EditAttributeModal
