import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WrapperFollowup } from './styles'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FollowupModel } from '../../entities'
import { useState } from 'react'
import { MoonLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import { getCAFileBase64 } from 'services/connector'

type Props = {
  followup: FollowupModel
}

const FollowupItem = ({ followup }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleDownload = async (fileId: string) => {
    setIsLoading(true)
    try {
      const response = await getCAFileBase64(fileId)

      if (!response) {
        throw new Error()
      }
      setIsLoading(false)
      const downloadLink = document.createElement('a')
      downloadLink.href = response
      downloadLink.download = fileId
      downloadLink.click()
    } catch (error) {
      setIsLoading(false)
      toast.error('Ops 😥, ocorreu um erro ao fazer download')
    }
  }
  return (
    <WrapperFollowup>
      <div className="followup-info">
        <div className="followup-info__date">
          <FontAwesomeIcon icon={faCircle} />
          <span className="create-info">Atividade registrada em:</span>
          <span className="data-followup">{followup.createdAt.split(' ')[0]}</span>
        </div>
        {followup.user && (
          <div className="followup-info__name">
            <span className="create-info">Por:</span>
            <span className="data-followup">{followup.user}</span>
          </div>
        )}
      </div>
      <div className="followup-description">
        {followup?.fileId ? (
          <div className="followup-description__file">
            <span>Vincular documento:</span>
            <div className="file">
              <button
                title="Baixar documento"
                onClick={() => {
                  handleDownload(followup?.fileId ?? '')
                }}
                disabled={isLoading}
              >
                {followup.fileId}
              </button>
              {isLoading && <MoonLoader size={15} color={'#123abc'} />}
            </div>
          </div>
        ) : (
          <span className="followup-description__text">{followup.description}</span>
        )}
      </div>
    </WrapperFollowup>
  )
}

export default FollowupItem
