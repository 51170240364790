/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { deleteFile } from '../../services/fileManager'
import { storeTicket } from 'services/connector'
import { CategoryType, FormI, TicketToSubmitModel } from '../../entities'
import { InputsToRender } from './InputsToRender'

import { Button } from '..'
import {
  formatDescriptionField,
  getCategoryInfoFromSelectedOption,
  getUserField,
  setFileField,
  updateUserAnswer
} from './utils'
import { useSetExtraQuestions } from 'hooks'

import { Form } from './styles'

type Props = {
  form: FormI
}

const FormGeneric = ({ form }: Props) => {
  const methods = useForm()
  const navigate = useNavigate()
  const isVip = localStorage.getItem('isHidden') === 'true'

  const { optionListSelected, handleCheckboxExtraQuestions, handleSelectExtraQuestions } =
    useSetExtraQuestions({ methods })

  const [isLoading, setIsLoading] = useState(false)
  const [userName, setUserName] = useState('')

  const [equipments, setEquipment] = useState<Record<string, number>>({})

  const onSubmitForm = async (data: Record<string, string | File>) => {
    setIsLoading(true)
    const attachmentsCa = await setFileField(data)
    try {
      const categoryData = getCategoryInfoFromSelectedOption(optionListSelected)
      const { question, answer } = getUserField(data, form)

      const newDataForDescription = updateUserAnswer(data, question, userName)

      const description = formatDescriptionField({
        data: newDataForDescription,
        attributes: form.attributes
      })

      const dto: TicketToSubmitModel = {
        tj_user_email: answer,
        status: (categoryData?.category?.status ?? form.category?.status) as string,
        categorie: (categoryData?.category?.categoryId ?? form.category?.categoryId) as string,
        group: (categoryData?.category?.group ?? form.category?.group) as string,
        description,
        summary: categoryData?.title ?? form.title,
        type: (categoryData?.category?.type ?? form.category?.type) as CategoryType,
        attachments_ca: attachmentsCa,
        created_via: process.env.REACT_APP_ORIGEN_ID as string,
        properties: equipments
      }

      const response = await storeTicket(dto)
      if (!response) {
        throw new Error()
      }
      setIsLoading(false)
      navigate(`/ticket/success/${response.ticket}`, {
        state: { user: dto.tj_user_email }
      })
    } catch (error: any) {
      setIsLoading(false)

      if (attachmentsCa.length > 0) {
        const urlFile = [{ name: attachmentsCa[0]?.file_url }]
        await deleteFile(urlFile)
      }
      if (error?.response?.data?.err?.message === 'username inválido') {
        toast.error('Seu usuário ainda não foi replicado no sistema de chamado, por favor aguarde')
      } else {
        toast.error('Ops 😥, ocorreu um erro ao abrir o chamado')
      }
    }
  }

  useEffect(() => {
    if (form?.attributes?.length > 0) {
      const sortAttributeForm = form.attributes.sort(
        (a, b) => (a.positionOrder as number) - (b.positionOrder as number)
      )
      sortAttributeForm.forEach((attribute) => {
        if (!(attribute.isHiddenToVip && isVip)) {
          methods.unregister(attribute.id.toString())
        }
      })
    }
  }, [form])

  const handleEquipmentSelection = (list: Record<string, number>) => {
    setEquipment(list)
  }

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmitForm)} $color={form.color}>
        {form?.parentName && (
          <div className="form-label">
            <span>ATENDIMENTO PARA:</span>
            <span>{form.parentName}</span>
          </div>
        )}

        <h2>Preencha os campos abaixo </h2>

        <div className="form-inputs">
          <InputsToRender
            attributes={form.attributes}
            isVip={isVip}
            setUserName={setUserName}
            handleCheckboxExtraQuestions={handleCheckboxExtraQuestions}
            handleSelectExtraQuestions={handleSelectExtraQuestions}
            optionListSelected={optionListSelected}
            handleEquipmentSelection={handleEquipmentSelection}
          />
        </div>

        <div className="form-button">
          <Button type="submit" isLoading={isLoading} text="Enviar" />
        </div>
      </Form>
    </FormProvider>
  )
}

export default FormGeneric
