import { useState, useRef, MouseEvent, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Card, Spinner } from '..'
import { CardModel } from '../../entities'

import { ContainerCarousel } from './styles'

type Props = {
  isOneRow?: boolean
  cardList: CardModel[]
  isLoadingCardList: boolean
}

const getScrollValueX = (): number => {
  const currentSizeScreen = window.innerWidth
  if (currentSizeScreen >= 1280) {
    return 8
  }
  if (currentSizeScreen < 1280 && currentSizeScreen >= 1024) {
    return 3
  }
  if (currentSizeScreen < 1024 && currentSizeScreen >= 768) {
    return 2
  }
  if (currentSizeScreen < 768) {
    return 1
  }
  return 1
}

function Carousel ({ isOneRow = false, cardList, isLoadingCardList }: Props) {
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [startPositionX, setStartPositionX] = useState(0)

  const refContainer = useRef<HTMLDivElement>(null)
  const refInner = useRef<HTMLDivElement>(null)

  const handelMouseDown = (e: MouseEvent<HTMLElement>) => {
    setIsMouseDown(true)
    setStartPositionX(e.nativeEvent.screenX)

    if (refContainer.current) {
      refContainer.current.style.cursor = 'grabbing'
    }
  }

  const handleMouseUp = () => {
    setIsMouseDown(false)
    if (refContainer.current) {
      refContainer.current.style.cursor = 'grab'
    }
  }

  const handleMouseMove = (e: MouseEvent<HTMLElement>) => {
    if (!isMouseDown) return
    e.preventDefault()
    if (refContainer.current) {
      // Move to right direction
      const scrollValue = getScrollValueX()
      if (e.nativeEvent.screenX < startPositionX) {
        refContainer.current?.scrollBy(scrollValue, 0)
        return
      }
      // Move to left direction
      if (e.nativeEvent.screenX > startPositionX) {
        refContainer.current?.scrollBy(-scrollValue, 0)
      }
    }
  }

  const handleOnclickBtn = (direction: string) => {
    const coordX = 200
    const scrollValue = direction === 'right' ? coordX : -coordX
    refContainer.current?.scrollBy(scrollValue, 0)
  }

  return (
    <ContainerCarousel $isOneRow={isOneRow}>
      {isLoadingCardList && <Spinner size={25}/>}
      {!isLoadingCardList && cardList.length === 0 && <p style={{ margin: 'auto' }}>Nenhum card encontrado!</p>}
      {cardList.length > 0 && (
        <>
          <button
            className="btn-carousel"
            onClick={() => {
              handleOnclickBtn('left')
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <div
            ref={refContainer}
            className="carousel-box--outer"
            onMouseDown={handelMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            <div ref={refInner} className="carousel-box--inner">
              {cardList.map((card: CardModel) => (
                <Fragment key={card.id}>
                  {card.isEnable ? (
                    <Card key={card.id} card={card} url={`/service/${card.id}`} isCarousel={true} />
                  ) : null}
                </Fragment>
              ))}
            </div>
          </div>
          <button
            className="btn-carousel btn-carousel-right"
            onClick={() => {
              handleOnclickBtn('right')
            }}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </>
      )}
    </ContainerCarousel>
  )
}

export default Carousel
