import { OptionSelect } from 'components/Select/Select'
import { CardModel } from '../../../entities'

export const buildCardOptions = (
  list: CardModel[],
  previewOptions: OptionSelect[] = [],
  parentName = ''
) => {
  const cardOptions = previewOptions

  list.forEach((card) => {
    if (card.children.length === 0 && card.forms.length === 0) {
      if (card.parent) {
        if (parentName) {
          cardOptions.push({
            label: `${parentName} > ${card.parent.name} > ${card.name} `,
            value: { id: card.id as number, title: card.name }
          })
        } else {
          cardOptions.push({
            label: `${card.parent.name} > ${card.name} `,
            value: { id: card.id as number, title: card.name }
          })
        }
      } else {
        cardOptions.push({
          label: `${card.name}`,
          value: { id: card.id as number, title: card.name }
        })
      }
    }
    if (card.children.length >= 0) {
      let name = ''
      if (card?.parent?.name && parentName !== '') {
        name = `${parentName} > ${card?.parent?.name}`
      }
      if (card?.parent?.name && parentName === '') {
        name = `${card?.parent?.name}`
      }
      buildCardOptions(card.children, cardOptions, name)
    }
  })

  return cardOptions
}
