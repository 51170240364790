import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin, breakQueriesMin } from '../../styles/Breakpoints'

const labelWidth = '5px'
export const cardHeight = {
  default: '6rem',
  lg: '8rem',
  xl: '10rem'
}

export const CardItem = styled.button<{ $isCarousel: boolean, $colorLabel: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 15rem;

  height: ${cardHeight.default};
  background: ${({ theme }) => theme.colors.white} 0% 0% no-repeat padding-box;
  opacity: 1;
  border: none;
  border-radius: 5px 7px 7px 5px;
  cursor: pointer !important;
  user-select: none;
  transition: all 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.gray._400};
  }

  .card-information {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - ${labelWidth});
    height: 100%;
    padding: 0.75rem .75rem;
    font-weight: 600;
  }

  .card-title {
    text-align: left;
    margin-left: 0.5rem;
    font-size: 0.85rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blue._800};
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* Media queries */
  ${breakQueriesMaxMin(SizeBreakpoint.md, SizeBreakpoint.xsm)} {
    padding-right: 0.75rem;
  }

  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.md)} {
    padding-right: 0.875rem;
  }

  ${breakQueriesMaxMin(SizeBreakpoint.xl, SizeBreakpoint.lg)} {
    padding-right: 0.875rem;
  }

  ${breakQueriesMaxMin(SizeBreakpoint.xxl, SizeBreakpoint.xl)} {
    .card-information {
      padding: 0.75rem 1rem;
    }
  }

  ${breakQueriesMin(SizeBreakpoint.xxl)} {
    .card-information {
      padding: 0.875rem 1.25rem;
    }
  }

  @media (min-height: 1200px) and (min-width: 1366px) {
    height: ${cardHeight.lg};
  }
`
export const CardLabelColor = styled.div<{ $colorLabel: string, $height?: string }>`
  width: ${labelWidth};
  height: ${(props) => props.$height ?? '100%'};
  border-radius: 1rem;
  background-color: ${(props) => props.$colorLabel || props.theme.colors.green._600};
`

export const CardIcon = styled.span<{ $padding?: string }>`
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
  background: ${({ theme }) => theme.colors.gray._50} 0% 0% no-repeat padding-box;
  border-radius: 50%;
  opacity: 1;
  /* padding: 0.85rem; */
  padding: ${(props) => props.$padding ?? ' 0.85rem'};

  svg {
    width: 1.85rem;
    height: 1.85rem;
    color: ${({ theme }) => theme.colors.blue._50};
  }

  /* Media queries */
  ${breakQueriesMaxMin(SizeBreakpoint.md, SizeBreakpoint.xsm)} {
    padding: ${(props) => props.$padding ?? ' 0.75rem'};
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.md)} {
    padding: ${(props) => props.$padding ?? ' 0.75rem'};
    svg {
      width: 1.65rem;
      height: 1.65rem;
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.xl, SizeBreakpoint.lg)} {
    padding: ${(props) => props.$padding ?? ' 0.85rem'};
    svg {
      width: 1.85rem;
      height: 1.85rem;
    }
  }
`
