import styled from 'styled-components'

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  button {
    padding: 0.15rem 0.5rem;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.blue._500};
    background-color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.gray._200};
    transition: all 0.3ms;

    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.blue._500};
    }
    &:disabled {
      color: ${({ theme }) => theme.colors.gray._450};
      cursor: not-allowed;
    }
  }

  span {
    padding: 0.15rem 0.75rem;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.blue._500};
    border: 1px solid ${({ theme }) => theme.colors.blue._500};
  }
`
