import { useMemo, useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import ImageResize from 'quill-image-resize-module-react'
import { ImageProps } from 'components/ArticlesAdmin/AddArticleModal'

import { ErrorMessage, FormEditorContentField } from './styles'

declare const window: any

window.Quill = Quill
Quill.register('modules/imageResize', ImageResize)

type Props = {
  images?: ImageProps[]
  setImages?: (images: ImageProps[]) => void
  files?: File[]
  setFiles?: (files: File[]) => void
  value?: string
  modulesEditor?: any
  validations?: any
  fieldName: string
  label: string
}

const EditorContentField = (props: Props) => {
  const {
    value,
    modulesEditor,
    validations,
    fieldName,
    images,
    setImages,
    files,
    setFiles,
    label
  } = props
  const quillRef = useRef<ReactQuill>(null)
  const {
    control,
    clearErrors,
    setValue,
    getValues,
    setError,
    formState: { errors }
  } = useFormContext()

  const modules = useMemo(() => {
    if (modulesEditor) {
      return modulesEditor
    }
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
          ['link', 'image'],
          ['clean']
        ],
        handlers: {
          image: handlerImage
        }
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handlerImage (e: any) {
    const input = document.createElement('input')

    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/jpg, image/jpeg, image/png')
    input.click()

    input.onchange = async () => {
      if (input.files) {
        const file: File = input?.files[0]
        const regex = /^.*.(jpg|JPG|png|PNG|jpeg|JPEG)$/
        const isValidFormat = regex.test(file.type)
        if (!isValidFormat) {
          setError('content', { type: 'error', message: '* Formato inválido' })
          return
        }
        if (files && setFiles) {
          const fileList = files
          fileList.push(file)
          setFiles(fileList)
        }
        const resp = await getBase64(file)

        const fileName = file.name
        insertToEditor(fileName, resp as string)
      }
    }
  }

  const getBase64 = async (file: File) =>
    new Promise(function (resolve, reject) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result)
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      reader.onerror = (error) => {
        reject(error)
      }
    })

  async function insertToEditor (fileName: string, imgBase64: string) {
    if (quillRef.current) {
      const range = quillRef.current.getEditorSelection()
      const index = range?.index as number
      const html = `<img src=${imgBase64} alt=${fileName} />`
      quillRef.current.getEditor().clipboard.dangerouslyPasteHTML(index, html)

      const image = { fileName, index, base64: imgBase64 }
      if (images && setImages) {
        const list = images
        list.push(image)
        setImages(list)
      }
    }
  }

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={validations}
      render={() => (
        <FormEditorContentField>
          <label htmlFor={fieldName} className="form-text-label">
            {label}
          </label>
          <ReactQuill
            ref={quillRef}
            modules={modules}
            theme="snow"
            value={getValues()[fieldName] ?? value}
            onChange={(e: any) => {
              clearErrors(fieldName)
              setValue(fieldName, e)
            }}
          />

          {errors[fieldName] && (
            <ErrorMessage>{errors[fieldName]?.message?.toString()}</ErrorMessage>
          )}
        </FormEditorContentField>
      )}
    />
  )
}

export default EditorContentField
