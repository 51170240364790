/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import { AttributeModel, SearchScopeType } from '../../entities'
import Spinner from 'components/Loadings/Spinner'
import { getSearchData, GetSearchDataResponse } from 'services/api'

import { ErrorMessage, FormInputSearch } from './styles'

type Props = {
  attribute: AttributeModel
  searchTerms?: string | null // Example: terms1 > terms2 > terms3 > ... termsN
}

const SearchField = ({ attribute, searchTerms = null }: Props) => {
  const {
    setValue,
    clearErrors,
    control,
    formState: { errors }
  } = useFormContext()
  const [dataFound, setDataFound] = useState<GetSearchDataResponse[]>([])
  const [dataSelected, setDataSelected] = useState<string | null>(null)
  const [valueTyped, setValueTyped] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const emptyData = dataFound.length === 0 && (valueTyped || searchTerms) && !dataSelected

  const handleSearch = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setDataSelected(null)
      return
    }
    setValueTyped(e.target.value)
    let query = ''

    if (searchTerms) {
      query = searchTerms.trim().replaceAll('>', ',').toLowerCase()
      query = query + ',' + e.target.value
    } else {
      query = e.target.value
    }

    getData(query)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Delete' || e.key === 'Backspace') {
      setDataSelected(null)
      setValueTyped('')
      getData(getDefaultQuery())
    }
  }

  const getData = async (query: string) => {
    try {
      setIsLoading(true)

      const response = await getSearchData({
        scope: attribute.searchScope as SearchScopeType,
        query
      })

      if (!response) {
        throw new Error()
      }
      setDataFound(response)
    } catch (error: any) {
      setDataFound([])
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao procurar os dados ')
    } finally {
      setIsLoading(false)
    }
  }

  const handleDataSelected = (data: GetSearchDataResponse) => {
    clearErrors(attribute.id.toString())
    setDataSelected(data.label)
    setValue(attribute.id.toString(), data.label)
    setDataFound([])
    setValueTyped('')
  }

  useEffect(() => {
    if (searchTerms) {
      getData(getDefaultQuery())
    }
  }, [])

  const getDefaultQuery = () => {
    let query = ''

    if (searchTerms) {
      query = searchTerms.trim().replaceAll('>', ',').toLowerCase()
    }
    return query
  }

  return (
    <Controller
      name={attribute.id.toString()}
      control={control}
      rules={{ required: attribute.required }}
      render={({ field: { onChange } }) => (
        <FormInputSearch>
          <div className="input-search">
            <label htmlFor={attribute.id.toString()}>{attribute?.question}</label>
            <div className="input-search__loading">
              <input
                type="search"
                name={attribute.id.toString()}
                autoComplete="off"
                placeholder={searchTerms ? 'Selecione o dado' : 'Digite o campo a pesquisar'}
                value={dataSelected ?? valueTyped}
                onChange={(e) => {
                  handleSearch(e)
                }}
                onKeyDown={(e) => {
                  handleKeyDown(e)
                }}
              />
              {isLoading && (
                <span className="loading">
                  {' '}
                  <Spinner size={20} marginTop="1rem" />
                </span>
              )}
            </div>
          </div>

          {!isLoading && dataFound.length > 0 && (
            <div className="input-search-list">
              <ul>
                {dataFound.map((data, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      handleDataSelected(data)
                    }}
                  >
                    {data.label.toUpperCase()}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {!isLoading && emptyData && (
            <div className="input-search-list">
              <div className="input-search-item">Nenhum dado encontrado</div>
            </div>
          )}
          {!isLoading && errors[attribute?.id.toString()] && (
            <ErrorMessage>{errors[attribute?.id.toString()]?.message?.toString()}</ErrorMessage>
          )}
          {errors[attribute.id.toString()]?.type === 'required' && (
            <ErrorMessage>* Campo Obrigatório</ErrorMessage>
          )}
        </FormInputSearch>
      )}
    />
  )
}

export default SearchField
