import { useState } from 'react'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { ArticleI } from './ArticleList'
import { articleFeedback, ArticleFeedbackProps } from 'services/api'
import { Spinner } from 'components'

import { ContainerAccordion } from './styles'

type Props = {
  article: ArticleI
  onOpenArticle: (id: string | number) => void
  formId: string | number
}

const ArticleItem = ({ article, onOpenArticle, formId }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleAnswer = async (value: boolean) => {
    try {
      setIsLoading(true)
      const { userName, userLogin } = JSON.parse(localStorage.getItem('user') as string)
      const dto: ArticleFeedbackProps = {
        userName,
        userLogin,
        formId,
        articleId: article.id,
        isUtil: value
      }

      const response = await articleFeedback(dto)
      if (!response) {
        throw new Error()
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao enviar o feedback ')
    } finally {
      setIsLoading(false)
      onOpenArticle(article.id)
    }
  }
  return (
    <ContainerAccordion $isAccordionOpen={article.isOpen}>
      <div
        className="accordion-toggle"
        onClick={() => {
          onOpenArticle(article.id)
        }}
      >
        <p>{article.title}</p>
        {article.isOpen ? (
          <FontAwesomeIcon icon={faCaretUp} />
        ) : (
          <FontAwesomeIcon icon={faCaretDown} />
        )}
      </div>
      {article.isOpen && (
        <div className="accordion-content">
          <div className="content" dangerouslySetInnerHTML={{ __html: article?.content }}></div>
          <div className="accordion-content-feedback">
            <p>Este artigo foi útil?</p>
            {isLoading && <Spinner size={25} marginTop="1rem" />}
            {!isLoading && (
              <div>
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={() => {
                    handleAnswer(true)
                  }}
                >
                  Sim
                </button>
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={() => {
                    handleAnswer(false)
                  }}
                >
                  Não
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </ContainerAccordion>
  )
}

export default ArticleItem
