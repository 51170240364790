import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin } from '../../styles/Breakpoints'

export const ContainerBreadcrumb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem 0;
  gap: 1.75rem;

  h4 {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.white};
    text-transform: uppercase;
  }

  ${breakQueriesMaxMin(SizeBreakpoint.md, SizeBreakpoint.xsm)} {
    gap: 1.5rem;
    h4 {
      font-size: 1.15rem;
    }
  }
`
