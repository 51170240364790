import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import QRCode from '../../assets/qr-code-whats.png'
import { ContainerContacts, ContainerTitle } from './styles'

const Contacts = () => {
  return (
    <ContainerContacts>
      <ContainerTitle>
        {'CONTATOS'}
        <span />
      </ContainerTitle>
      <div className="contacts-content">
        <p>Se estiver precisando de outra ajuda ou outras informações, entre em contato:</p>
        <div className="contacts-phone">
          <span>
            <FontAwesomeIcon icon={faPhoneVolume} />
            0800 071 8522
          </span>
          <Link to="https://wa.me/+557133247400?text=Sou%20Judi,%20Assistente%20Virtual%20do%20TJBA.%20Em%20que%20posso%20lhe%20ajudar?" target='_blank'>
            <FontAwesomeIcon icon={faWhatsapp} />
            (71) 3324-7400
          </Link>
        </div>
        <div className="contacts-qrcode">
          <p>
            Você pode nos contatar facilmente usando o QR Code. Basta ativar a câmera do seu celular, apontá-la para o código ao lado e pronto. Alternativamente, você pode clicar no número ao lado para acessar o WhatsApp Web.
          </p>
          <span>
            <img src={QRCode} alt="qrcode" />
          </span>
        </div>
      </div>
    </ContainerContacts>
  )
}

export default Contacts
