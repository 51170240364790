import { useState } from 'react'
import { toast } from 'react-toastify'
import { ModalContent } from 'components'
import { ArticleModel } from '../../entities'
import { deleteMultiplePublicFile } from 'services/fileManager'
import { deleteArticle } from 'services/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { ModalBodyDelete } from 'components/Modal/styles'

type Props = {
  toggleModal: () => void
  articleSelected: ArticleModel
  getArticles: () => void
}

const DeleteArticleModal = ({ toggleModal, articleSelected, getArticles }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleDelete = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const response = await deleteArticle(articleSelected.id)
      if (!response) {
        throw new Error()
      }
      if (articleSelected.files && articleSelected?.files?.length > 0) {
        await deleteMultiplePublicFile(articleSelected.files)
      }
      setIsLoading(false)
      getArticles()
    } catch (error: any) {
      setIsLoading(false)
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao remover a FAQ')
    } finally {
      toggleModal()
    }
  }

  return (
    <ModalContent
      title="Remover Faq"
      handleCloseModal={toggleModal}
      handleSubmit={handleDelete}
      isLoading={isLoading}
      btnText="Confirmar"
      btnColor="#cf1f3f"
    >
      <ModalBodyDelete>
        <div className="question">
          <span className="icon">
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </span>
          <p>Deseja remover esta FAQ?</p>
        </div>
        <div className="text">
          <p>{articleSelected.title}</p>
        </div>
      </ModalBodyDelete>
    </ModalContent>
  )
}

export default DeleteArticleModal
