import { getFileUrlPublic } from 'services/fileManager'

export const validations = {
  pattern: {
    value: /^^.{1,150}$/,
    message: '* Máximo 120 caracteres'
  },
  required: {
    value: true,
    message: '* Campo obrigatório'
  }
}

export const getUrlFile = async (file: File) => {
  const formData = new FormData()
  formData.append('files', file)
  const response = await getFileUrlPublic(formData)
  if (!response.data) {
    throw new Error()
  }
  return response.data[0].name
}

export const reactQuillContainerParams = [[{ header: [false] }], ['bold', 'italic', 'underline']]
