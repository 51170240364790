import { ClipLoader } from 'react-spinners'
import { WrapperSpinner } from './styles'
import { HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<HTMLDivElement> {
  size?: number
  marginTop?: string
}

const Spinner = ({ size = 45, marginTop = '3rem', ...props }: Props) => {
  return (
    <WrapperSpinner $marginTop={marginTop} {...props}>
      <ClipLoader color="#002356" size={size} aria-label="Loading Spinner" data-testid="loader" />
    </WrapperSpinner>
  )
}

export default Spinner
