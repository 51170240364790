import { ISurveyFormInput } from '../CreateSurveyModal'

export const defaultValues: ISurveyFormInput = {
  title: '',
  identifier: 'feedback',
  description: '',
  isEnable: false,
  surveyQuestions: [
    {
      type: 'text',
      question: '',
      required: true,
      scaleLimit: 0,
      positionOrder: 1,
      label: null,
      options: null
    }
  ]
}
