import { btnSize } from 'components/Home/styles'
import styled from 'styled-components'

export const maxWidthInBigScreen = {
  lg: '80%',
  xl: '70%'
} // apply for minWidth 2560px

const heightHeader = '6rem'
const heightFooter = '2.5rem'

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray._200};
  overflow-x: hidden !important;

  @media (max-width: 500px) {
    width: fit-content !important;
    overflow-x: scroll !important;
  }
`

export const Header = styled.header`
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: ${heightHeader};
  min-height: ${heightHeader};
  max-height: ${heightHeader};
  background-color: ${({ theme }) => theme.colors.white};

  .responsive_container {
    width: 100%;
    height: 100%;
    padding: 1rem ${btnSize.lg};
    display: flex;
    justify-content: space-between;
  }

  .header-logo__info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    img {
      width: 4rem;
      max-width: 4rem;
      height: 4rem;
      max-height: 4rem;
    }

    span {
      font-size: 1.25rem;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.blue._800};
      text-transform: uppercase;
    }
  }

  .header-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;

    .header-info-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid ${({ theme }) => theme.colors.blue._500};
      border-radius: 50%;
      padding: 0.15rem;

      svg {
        font-size: 1rem;
        color: ${({ theme }) => theme.colors.blue._500};
      }
    }

    div > p:first-child {
      font-size: 1.25rem;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.blue._500};
    }
    div > p:last-child {
      color: ${({ theme }) => theme.colors.gray._450};
    }
  }
  @media (min-width: 2048px) {
    display: flex;
    justify-content: center;
    .responsive_container {
      width: ${maxWidthInBigScreen.lg};
    }
  }

  @media (min-width: 2560px) {
    display: flex;
    justify-content: center;
    .responsive_container {
      width: ${maxWidthInBigScreen.xl};
    }
  }
`

export const Sidebar = styled.nav`
  float: left;
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 1.25rem 0 1.25rem 1.25rem;
  background-color: ${({ theme }) => theme.colors.blue._500};
  font-size: 0.875rem;
  box-shadow: 0px 6px 10px ${({ theme }) => theme.colors.gray._900};

  a {
    display: flex;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0.5rem 0;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.white};
    padding-left: 0.75rem;
    margin-bottom: 0.5rem;
    margin-right: 1rem;

    svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
    }

    &:hover {
      text-decoration: underline;
    }
  }
  a.active {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.blue._800};
    width: 100%;
    padding-left: 0.75rem;
    border-radius: 0.875rem 0 0 0.875rem;
  }

  /* a.link-bottom {
    margin-top: auto;
  } */

  @media (max-width: 768px) {
    width: 5rem;
    min-width: 5rem;
    max-width: 5rem;

    a {
      svg {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0;

        &:hover {
          color: ${({ theme }) => theme.colors.blue._800};
        }
      }
    }
  }
  @media (min-width: 2048px) {
    background-color: transparent;
    box-shadow: 0px 6px 10px transparent;
    margin-top: 1rem;

    a,
    svg {
      color: ${({ theme }) => theme.colors.blue._800} !important;
    }

    a.active {
      background-color: ${({ theme }) => theme.colors.gray._50};
      border: none;
      border-radius: 0;
    }

    /* a.link-bottom {
      margin-top: 0;
    } */
  }
`

export const WrapperContentSidebar = styled.div`
  width: 100%;
  flex: 1 0 auto;

  .responsive_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - ${heightHeader} - ${heightFooter});
    margin: 0 auto;
  }

  .wrapper-content {
    display: flex;
    flex-direction: column;
    margin: 3rem;
    width: 100%;


  }

  @media (min-width: 2048px) {
    .responsive_container {
      width: ${maxWidthInBigScreen.lg};
    }
  }

  @media (min-width: 2560px) {
    .responsive_container {
      width: ${maxWidthInBigScreen.xl};
    }
  }
`

export const ContainerFooter = styled.footer`
  flex-shrink: 0;
  width: 100%;
  height: ${heightFooter};
  max-height: ${heightFooter};
  min-height: ${heightFooter};
  box-shadow: 3px 3px 20px ${({ theme }) => theme.colors.gray._900};
  background: ${({ theme }) => theme.colors.blue._800} 0% 0% no-repeat padding-box;

  .responsive_container {
    padding: 0rem ${btnSize.lg};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  p {
    text-align: center;
    font-size: 0.875rem;
    letter-spacing: 0px;
    color: ${({ theme }) => theme.colors.white};
    opacity: 1;
  }

  @media (min-width: 2048px) {
    display: flex;
    justify-content: center;
    .responsive_container {
      width: ${maxWidthInBigScreen.xl};
    }
  }

  @media (min-width: 2560px) {
    display: flex;
    justify-content: center;
    .responsive_container {
      width: ${maxWidthInBigScreen.xl};
    }
  }
`
