import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { ModalContent } from 'components'
import { createNewAdmin } from 'services/api'
import { ModalBodyForm } from 'components/Modal/styles'
import { TextField } from 'components/Fields'

interface IFormInput {
  name: string
  login: string
}

type Props = {
  toggleModal: () => void
  getAdmins: () => void
}

const AddAdminModal = ({ toggleModal, getAdmins }: Props) => {
  const methods = useForm<IFormInput>()
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    setIsLoading(true)
    try {
      const response = await createNewAdmin(data.name, data.login)
      if (!response) {
        throw new Error()
      }
      setIsLoading(false)
      getAdmins()
    } catch (error: any) {
      setIsLoading(false)
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao adicionar um novo administrador')
    } finally {
      methods.reset()
      toggleModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Novo administrador"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
      >
        <ModalBodyForm>
          <TextField
            attribute={{
              // id: 1,
              id: 'name',
              question: 'Digite o nome:',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false
            }}
          />
          <TextField
            attribute={{
              // id: 2,
              id: 'login',
              question: 'Digite o login:',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false
            }}
          />
        </ModalBodyForm>
      </ModalContent>
    </FormProvider>
  )
}

export default AddAdminModal
