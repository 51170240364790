import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin, breakQueriesMin } from '../../styles/Breakpoints'
import { cardHeight } from 'components/Card/styles'

export const widthTicketBox = '25rem'
export const heightTicketBox = {
  default: '4.5rem',
  lg: '5.5rem',
  xl: '6.5rem'
}
export const mbTicketBox = '0.25rem' // Margin bottom

export const btnSize = {
  lg: '2.5rem',
  md: '2.25rem'
  // sm: '2rem'
}

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
  color: ${({ theme }) => theme.colors.blue._800};
  font-weight: bold;
  text-transform: uppercase;

  span {
    background-color: ${({ theme }) => theme.colors.blue._800};
    height: 2px;
    width: 2rem;
  }

  @media screen and (max-width: 1280px) and (max-height: 768px) {
    margin-bottom: 0.5rem;
  }
`

//* ****************************** */
//  Styles> TicketList component
//* ****************************** */
export const ContainerTickets = styled.div`
  display: flex;
  flex-direction: column;
  .ticket-item:last-child {
    margin-bottom: 0;
  }

  .ticket-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: ${heightTicketBox.default};
    height: ${heightTicketBox.default};
    /* min-width: ${widthTicketBox}; */
    padding: 0.625rem 1.5rem;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0.875rem;
    margin-bottom: ${mbTicketBox} !important;

    &:hover {
      background: ${({ theme }) => theme.colors.gray._400};
    }

    .ticket-item-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      text-align: left;
      color: ${({ theme }) => theme.colors.gray._500};

      span:nth-child(1) {
        font-size: 0.75rem;
        margin-bottom: 4px;
      }

      span:nth-child(2) {
        font-size: 0.875rem !important;
        margin-bottom: 4px;
        font-weight: bold;
      }
    }

    .ticket_icons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.25rem;
      gap: 0.875rem;
    }

    .arrow-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.colors.blue._800};
      cursor: pointer;

      svg {
        font-size: 1.75rem;
      }

      &:hover {
        svg {
          font-size: 2rem;
        }
      }
    }
  }

  ${breakQueriesMaxMin(1320, SizeBreakpoint.xl)} {
    .ticket-item {
      padding: 0.6rem 1.5rem;
      .ticket-item-info {
        span:nth-child(2) {
          font-size: 0.75rem !important;
          margin-bottom: 4px;
          font-weight: bold;
        }
      }
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.xl, SizeBreakpoint.lg)} {
    .ticket-item {
      padding: 0.6rem 1.5rem;
      .ticket-item-info > span:nth-child(2) {
        font-size: 0.75rem !important;
      }
    }
  }
  ${breakQueriesMaxMin(1050, 910)} {
    .ticket-item {
      padding: 0.5rem 0.75rem;
      .ticket-item-info {
        span:nth-child(1) {
          margin-bottom: 3px;
        }

        span:nth-child(2) {
          font-size: 0.7rem !important;
          margin-bottom: 3px;
        }
      }
    }
  }

  ${breakQueriesMaxMin(910, SizeBreakpoint.md)} {
    .ticket-item {
      padding: 0.5rem 0.5rem;
      .ticket-item-info {
        span:nth-child(1) {
          margin-bottom: 3px;
        }

        span:nth-child(2) {
          font-size: 0.65rem !important;
          margin-bottom: 3px;
        }
      }
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.md, SizeBreakpoint.xsm)} {
    .ticket-item {
      min-width: fit-content;
    }
  }

  @media (max-height: 768px) {
    .ticket-item {
      padding: 0.5rem 1.5rem;
    }
    .ticket-item-info > span:nth-child(2) {
      font-size: 0.75rem !important;
      margin-bottom: 4px;
      font-weight: bold;
    }
  }

  @media (min-height: 900px) and (max-height: 1080px) and (min-width: 1366px) {
    .ticket-item {
      min-height: ${heightTicketBox.lg};
      height: ${heightTicketBox.lg};
      padding: 0.825rem 1.5rem;
    }
  }
`

export const TicketStatus = styled.span<{ $statusColor: string }>`
  font-size: 0.75rem;
  font-weight: bold;
  color: ${(props) => props.$statusColor ?? props.theme.colors.gray._500};
`

//* ****************************** */
//  Styles> NewsCarousel component
//* ****************************** */
export const ContainerNewsCarousel = styled.div<{ $widthScreen: string }>`
  display: flex;
  flex-direction: column;
  width: ${widthTicketBox};
  justify-self: center;

  @media (min-width: 3840px) {
    width: 80%;
  }

  ${breakQueriesMaxMin(3840, 1920)} {
    width: 80%;
  }

  ${breakQueriesMaxMin(1920, SizeBreakpoint.xxl)} {
    width: 29rem;
  }
  ${breakQueriesMaxMin(SizeBreakpoint.xxl, 1320)} {
    width: 26rem;
  }

  ${breakQueriesMaxMin(1320, 1100)} {
    width: 23rem;
  }
  ${breakQueriesMaxMin(1100, 910)} {
    width: 19rem;

  }

  ${breakQueriesMaxMin(910, 810)} {
    width: 17rem;

  }

  ${breakQueriesMaxMin(810, SizeBreakpoint.md)} {
    width: 15rem;

  }

 ${breakQueriesMaxMin(SizeBreakpoint.md, SizeBreakpoint.xsm)} {
    width: ${(props) =>
      props.$widthScreen ? `calc(${props.$widthScreen} - ${btnSize.md} * 2)` : '100%'};
  }

`

export const ContainerContacts = styled.div`
  display: flex;
  flex-direction: column;
  /* width: ${widthTicketBox}; */

  .contacts-content {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    border-radius: 0.875rem;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 1.5rem;
    gap: 0.65rem;
    min-height: calc((${heightTicketBox} * 4) + (${mbTicketBox} * 3));
    height: calc(${heightTicketBox} * 4);
    height: 100%;

    p {
      font-size: 0.875rem;
    }

    .contacts-phone {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      color: ${({ theme }) => theme.colors.blue._800};
      font-weight: bold;

      svg {
        width: 1.1rem !important;
        height: 1.1rem !important;
      }

      a {
        color: ${({ theme }) => theme.colors.blue._800};
        svg {
          color: ${({ theme }) => theme.colors.green._600};
        }
      }

      svg {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.35rem;
        margin-top: 0.25rem;
      }
    }

    .contacts-qrcode {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.25rem;
      gap: 1rem;

      img {
        width: 7.5rem;
      }
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.md)} {
    .contacts-content {
      padding: 1rem 1.5rem 0.75rem 1.5rem;
      .contacts-phone {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .contacts-qrcode {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0.25rem;
        gap: 0.5rem;

        p {
          font-size: 0.75rem;
        }
        img {
          width: 7rem;
        }
      }
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.xl, SizeBreakpoint.lg)} {
    .contacts-content {
      padding: 1rem 1.5rem;
      p {
        font-size: 0.75rem;
      }
      .contacts-phone {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .contacts-qrcode {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0.25rem;
        gap: 0.5rem;

        p {
          font-size: 0.75rem;
        }
        img {
          width: 7rem;
        }
      }
    }
  }
`

//* ****************************** */
//  Styles> Carousel component
//* ****************************** */
export const ContainerCarousel = styled.div<{ $isOneRow: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: ${(props) => (props.$isOneRow ? '2rem' : '0')};

  height: ${(props) =>
    props.$isOneRow ? `calc(${cardHeight.default} + .75rem)` : `calc(${cardHeight.default} * 2 + 1.85rem)`};

  .btn-carousel {
    position: absolute;
    cursor: pointer;
    width: ${btnSize.lg};
    height: 100%;
    background: rgba(255, 255, 255, 0.6) !important;
    border-radius: 0.625rem;
    border: none;
    color: ${({ theme }) => theme.colors.gray._500};

    svg {
      width: 1.85rem;
      height: 1.85rem;
    }
  }

  .btn-carousel-right {
    right: 0;
  }

  .carousel-box--outer {
    width: calc(100% - ${btnSize.lg} * 2);
    height: 100%;
    overflow: hidden;
    position: absolute;
    margin: 0 ${btnSize.lg};
    margin-top: 5px;
    /* overflow-x: scroll; */
  }

  .carousel-box--inner {
    display: grid;
    grid-template-rows: ${(props) =>
      props.$isOneRow ? 'repeat(1, minmax(0, 1fr))' : 'repeat(2, minmax(0, 1fr))'};
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    align-content: center;
    padding-left: 0.875rem;
    gap: 1.25rem;
    height: 100%;
    /* pointer-events: none; */
  }

  /* Media queries */
  ${breakQueriesMaxMin(SizeBreakpoint.sm, SizeBreakpoint.xsm)} {
    margin-bottom: ${(props) => (props.$isOneRow ? '1rem' : '0')};

    .btn-carousel {
      width: ${btnSize.md};
    }

    .carousel-box--outer {
      width: calc(100% - ${btnSize.md} * 2);
      margin: 0 ${btnSize.md};
    }

    .carousel-box--inner {
      padding-left: 0.75rem;
      gap: 1rem;
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.md, SizeBreakpoint.sm)} {
    /* height: 12rem; */
    margin-bottom: ${(props) => (props.$isOneRow ? '1rem' : '0')};

    .btn-carousel {
      width: ${btnSize.md};
    }

    .carousel-box--outer {
      width: calc(100% - ${btnSize.md} * 2);
      margin: 0 ${btnSize.md};
    }

    .carousel-box--inner {
      padding-left: 0.75rem;
      gap: 1rem;
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.md)} {
    /* height: 12.5rem !important; */
    margin-bottom: ${(props) => (props.$isOneRow ? '1.5rem' : '0')};

    .btn-carousel {
      width: ${btnSize.md};
    }

    .carousel-box--outer {
      width: calc(100% - ${btnSize.md} * 2);
      margin: 0 ${btnSize.md};
    }

    .carousel-box--inner {
      padding-left: 1rem;
      gap: 1.15rem;
    }
  }

  @media (min-height: 1200px) and (min-width: 1366px) {
    height: ${cardHeight.xl};
    height: ${(props) =>
      props.$isOneRow ? `calc(${cardHeight.lg} + .75rem)` : `calc(${cardHeight.lg} * 2 + 1.85rem)`};
  }
`

//* ****************************** */
//  Styles> CardList component
//* ****************************** */
export const ContainerCards = styled.div<{ $isOneRow: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2rem;
  padding-left: 3rem;
  width: 100%;
  height: fit-content;
  /* height: ${(props) => (props.$isOneRow ? 'fit-content' : '13.85rem')}; */
  min-height: ${(props) => (props.$isOneRow ? '6rem' : '0')};
  margin-bottom: ${(props) => (props.$isOneRow ? '2rem' : '0')};
  gap: 2rem;

  .cards-content {
    display: grid;
    grid-template-rows: ${(props) =>
      props.$isOneRow ? 'repeat(1, minmax(0, 1fr))' : 'repeat(2, minmax(0, 1fr))'};
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    align-content: center;
    row-gap: 1.35rem;
    column-gap: 1.75rem;
    height: 100%;
    width: 100%;
  }

  ${breakQueriesMaxMin(SizeBreakpoint.xxl, SizeBreakpoint.xl)} {
    .cards-content {
      column-gap: 1.5rem !important;
    }
  }

  ${breakQueriesMin(SizeBreakpoint.xxl)} {
    .cards-content {
      column-gap: 3rem;
    }
  }
`
