import { BtnNotificationBell } from 'components'
import { IPartialTicket } from '../../entities'

type Props = {
  ticket: IPartialTicket
  toggleModal: (value: IPartialTicket) => void
  surveysAnswered: null | Record<string, boolean>
}

const BtnSurveyTicket = ({ ticket, toggleModal, surveysAnswered }: Props) => {
  const getComponent = () => {
    if (surveysAnswered) {
      for (const [key, value] of Object.entries(surveysAnswered)) {
        if (ticket.ticketID === key && !value && ticket.status?.toUpperCase() === 'ENCERRADO') {
          return (
            <BtnNotificationBell
            onClick={() => {
              toggleModal(ticket)
            }}
          />
          )
        }
      }
      return null
    }
  }

  return (
    <>
      {getComponent()}
    </>
  )
}
export default BtnSurveyTicket
