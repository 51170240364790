import { useState } from 'react'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import ReactSwitch from 'react-switch'
import { SingleValue } from 'react-select'
import { CSS } from '@dnd-kit/utilities'
import { useSortable } from '@dnd-kit/sortable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGrip } from '@fortawesome/free-solid-svg-icons'

import { Select } from 'components'
import { OptionSelect } from 'components/Select/Select'
import { InputType, SurveyQuestionModel } from '../../../entities'
import { renderSurveyContent, surveyTypeFieldOptions } from './utils'

import { ContainerField, ErrorMessage, customSelectStyles } from '../AttributeFields/styles'
import { ISurveyQuestionProps } from './SurveyFieldList'

type Field = 'question' | 'type' | 'positionOrder' | 'required' | 'scaleLimit' | 'options' | any

type Props = {
  attribute: SurveyQuestionModel | ISurveyQuestionProps
  question: Field
  type: Field
  positionOrder: Field
  required: Field
  scaleLimit: Field
  options: Field | null
  id?: string | number
  index: number
}

const SurveyField = <T extends FieldValues>({
  attribute,
  question,
  type,
  positionOrder,
  id = '',
  required,
  scaleLimit,
  options,
  index
}: Props) => {
  const {
    register,
    formState: { errors },
    control,
    getValues
  } = useFormContext<T>()

  const [fieldType, setFieldType] = useState<InputType>(attribute?.type as InputType)
  const defaultOptionType = surveyTypeFieldOptions.find((opt) => opt.value === attribute.type)
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  const errorQuestion = errors?.surveyQuestions ? (errors as any).surveyQuestions[index].question : null

  return (
    <ContainerField ref={setNodeRef} style={style}>
      <div className="btn-move">
        <button type="button" title="Mover" {...attributes} {...listeners}>
          <FontAwesomeIcon icon={faGrip} />
        </button>
      </div>

      <div className="header">
        <div className="section-question">
          <input
            type="text"
            placeholder="Digite aqui a pergunta"
            {...register(question, { required: '* Campo obrigatório' })}
          />
          {errorQuestion && <ErrorMessage>{errorQuestion.message?.toString()}</ErrorMessage>}
        </div>

        <Controller
          name={type}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <div className="section-type">
              <Select
                defaultValue={defaultOptionType}
                optionValues={surveyTypeFieldOptions}
                onChange={(e) => {
                  setFieldType((e as SingleValue<OptionSelect>)?.value as InputType)
                  onChange((e as SingleValue<OptionSelect>)?.value)
                }}
                styles={customSelectStyles}
                menuPlacement="bottom"
              />
            </div>
          )}
        />
      </div>

      <div className="content">
        {renderSurveyContent({ type: fieldType, optionsSelectElementFieldName: options })}
      </div>

      <div className="footer">
        {getValues(type) === 'stars' && (
          <div className="section-number">
            <input defaultValue={5} type="number" min='1' max='10' {...register(scaleLimit, { min: 1, max: 10 })} />
            <label htmlFor="scaleLimit">Escala máxima</label>
          </div>
        )}

        <div className="section-number">
          <input type="number" min='0' {...register(positionOrder, { min: 0 })} />
          <label htmlFor="positionOrder">Ordem de exibição</label>
        </div>

        <Controller
          name={required}
          control={control}
          rules={{ required: false }}
          render={({ field: { onChange } }) => (
            <div className="section-switch">
              <ReactSwitch
                onChange={(e) => {
                  onChange(e)
                }}
                checked={getValues(required) ?? true}
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={16}
                width={48}
                className="react-switch"
                id="material-switch"
              />
              <label htmlFor="required">Campo obrigatório</label>
            </div>
          )}
        />
      </div>
    </ContainerField>
  )
}

export default SurveyField
